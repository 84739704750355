const en = {
  "global": {
    "language": "English",
    "customer_service": "Customer Service",
    "customer_account_tips": "⚠️If this is not your channel, there may be restrictions on using the service.",
    "customer_service_tips": "Please contact [{0}] if you have any questions.🧏‍♀️",
    "customer_service_subtitle": "Please describe the problem you are experiencing in as much detail as possible by contacting us {0}",
    "customer_service_link": "Customer Service",
    "customer_service_email": "Customer service e-mail",
    "email_holder": "Enter e-mail address",
    "email_error": "Incorrect e-mail format",
    "password_holder": "Enter password",
    "password_error": "8 to 20 digits, must contain at least one uppercase and lowercase letter, special character, and number.",
    "password_confirm_error": "Incorrect password",
    "phone_holder": "Enter mobile phone number",
    "code_holder": "Enter verification code",
    "code_error": "Incorrect verification code format",
    "code_get": "Send",
    "code_resend": "Send again",
    "agreement_all_agree": "Agree to all",
    "agreement_age_14": "I am over 14 years old.",
    "agreement_age_18": "I am over 18 years old.",
    "protocol_checkbox_user": "{0} Terms of Use",
    "protocol_checkbox_privacy": "{0} Privacy Policy",
    "agreement_message_tip": "You must agree to the agreements to proceed.",
    "btn_add": "Add",
    "btn_change": "Edit",
    "btn_delete": "Delete",
    "btn_confirm": "Confirm",
    "btn_consider": "Let me think",
    "btn_close": "Close",
    "btn_submit": "Submit",
    "btn_cancel": "Cancel",
    "btn_know": "Got it",
    "btn_last_step": "Previous",
    "btn_next_step": "Next",
    "btn_agree": "Agree",
    "btn_disagree": "Disagree",
    "btn_go": "Shortcut",
    "btn_signin": "Log in",
    "btn_google_signin": "Log in with Google",
    "btn_signup": "Sign up",
    "btn_send": "Send",
    "btn_verify": "Verification",
    "btn_done": "Completed",
    "btn_save": "Save",
    "btn_put_away": "Fold",
    "btn_expand": "See more",
    "btn_edit": "Edit",
    "btn_check": "more",
    "btn_view_in_mobile": "Use in mobile",
    "modal_open_platform_title": "Please begin by opening your channel first.",
    "modal_open_platform_subtitle": "Meet more global subscribers by opening platforms",
    "modal_open_platform_btn": "Apply for platform opening",
    "total_count": "{0} data",
    "list_no_more": "All data have been checked.",
    "message_success_create": "Successfully added",
    "message_error_create": "Failed to add",
    "message_success_update": "Successfully edited",
    "message_error_update": "Failed to edit",
    "message_success_delete": "Successfully deleted",
    "message_error_delete": "Failed to delete",
    "message_success_upload": "Successfully uploaded",
    "message_error_upload": "Failed to upload",
    "message_success_bind": "Successfully linked",
    "message_error_bind": "Failed to link",
    "message_no_token": "Log in timed out, please log in again",
    "message_no_permission": "You don't have permission",
    "message_sms_sended": "The verification code has been sent",
    "message_sms_error": "Failed to send verification code",
    "message_email_sended": "The code has been sent to your e-mail",
    "message_email_error": "Failed to send code to e-mail",
    "message_verify_success": "Verification successful",
    "message_verify_fail": "Verification failed",
    "message_code_invalid": "The verification code is incorrect or has expired!",
    "not_found_title": "The page you are looking for is not found",
    "not_found_tips": "You may have entered through the wrong path",
    "empty_no_data": "There is no data",
    "empty_on_dev": "In preparation",
    "upgrading_tip": "Coming Soon",
    "upload_avatar": "Profile picture",
    "device_reminder_content": "This website is optimized for desktop browsers. Some features may be limited when accessed from mobile devices.",
    "trial_watermark": "adobaro trial version",
    "platform_hao_kan": "Haokan Video",
    "platform_tencent": "Tencent Video",
    "platform_iqiyi": "iQIYI",
    "platform_bilibili": "bilibili",
    "platform_xi_gua": "Xigua Video",
    "platform_wei_bo": "Weibo",
    "platform_youtube": "YouTube",
    "platform_dou_yin": "Douyin",
    "platform_red_book": "Xiaohongshu",
    "platform_kuai_shou": "Kuaishou",
    "platform_cloud_music": "NetEase Cloud Music",
    "platform_wechat_video": "WeChat Video",
    "platform_mei_pai": "Meipai",
    "platform_da_yu": "Dayuhao",
    "platform_dian_tao": "Diantao",
    "platform_qq_world": "QQ World",
    "platform_tiktok": "TikTok",
    "platform_instagram": "Instagram",
    "platform_twitter": "Twitter",
    "country_USA": "US",
    "country_CAN": "Canada",
    "country_KRW": "Korea (South)",
    "country_CHN": "China"
  },
  "dashboard": {
    "home_page": "Dashboard",
    "material_management": "File storage",
    "content_management": "Content",
    "platform_management": "Channel",
    "ai_subtitle": "AI Subtitle",
    "data_analysis": "Data analysis",
    "commercial_monetization": "Business management",
    "wallet": "My wallet",
    "repost_management": "Copyright management",
    "creative_laboratory": "Lab",
    "setting": "Settings",
    "feedback": "Feedback",
    "close_sidebar": "Fold",
    "upload_video": "Upload",
    "trial_btn_exit": "Finish trial version",
    "customer_service": "Help Desk",
    "member": "My Plan",
    "member_expired_days": "Ends in {0} days",
    "member_expire_today": "Ends today",
    "lang_language": "Language",
    "lang_zh_cn": "中文(简体)",
    "lang_ko_kr": "한국어",
    "lang_en_eu": "English",
    "auth": "Global PASS",
    "auth_certified": "Verified",
    "auth_uncertified": "Unverified",
    "account_management": "Multi-Channel Management",
    "sign_out": "Log out",
    "out_of_service_title": "Labor Day Service Notice",
    "out_of_service_content": "Hello, this is team adobaro. \n\nApril 31st ~ May 4th EST (May 1st ~ 5th,UTC+8) is the Chinese Labor Day holiday period.\n \nThe business hours of some services on each video platform may be limited during the holiday period. \nThe use of our service may not be smooth due to this. \n\nWe always admire your brave adventure! \nThank you.",
    "out_of_service_content_time": "April 31st ~ May 4th EST (May 1st ~ 5th,UTC+8)",
    "out_of_service_guide": "adobaro Guide",
    "out_of_service_customer": "Customer Service Inquiry",
    "out_of_service_tips": "Customer Center Operating Hours: 20:30~5:30 EST (9:30~18:30 KST), (excluding holidays)"
  },
  "sign": {
    "signin_intro_title": "Safe and Easy \nGlobal Expansion",
    "signin_intro_2_steps": "2-step Content Screening & Copyright Protection",
    "signin_intro_cross_platform": "Cross-Platform Operations: From Content Distribution to Data Monitoring",
    "signin_login_title": "Login",
    "signin_enter_password": "Enter Password",
    "password_check_1": "8~20 characters",
    "password_check_2": "Upper & lowercase letters",
    "password_check_3": "At least one special character & number",
    "password_incorrect_format": "Incorrect password format",
    "signin_title": "Welcome!",
    "signin_subtitle": "From channel opening to revenue settlement!\nMeet all the know-how of Chinese platform operation through adobaro.",
    "forgot_password": "Forgot your password?",
    "signup": "Sign up",
    "signup_tip": "{0} if you don't have an account",
    "account_not_exist": "We couldn't find the account information you entered.\nPlease check and try again.",
    "signin_error": "Failed to log in",
    "google_signin_error": "Failed to log in with Google",
    "signin_warning": "An overseas IP/security issue has been detected. Please proceed with security verification from the email you signed up for.",
    "signin_fail": "Incorrect password",
    "too_many_requests": "Please try again later.",
    "modal_signup_tips1": "We have sent an Email with a Link to set up your password.",
    "modal_signup_tips2": "Please check your inbox and set up your password",
    "modal_signup_tips3": "*Check your spam in case you haven't received the email.",
    "modal_signup_btn": "Confirm",
    "modal_welcome_tips1": "Welcome to adobaro!",
    "modal_welcome_tips2": "The All-in-One smart solution for your channel growth",
    "modal_welcome_tips3": "Start your experience now",
    "modal_welcome_btn": "Open channel",
    "modal_forgot_title": "Find my password",
    "modal_forgot_tips1": "Confirm to receive an Email with a password reset link",
    "modal_forgot_tips2": "Check your Email inbox and reset your password",
    "modal_forgot_tips3": "*Check your spam in case you haven't received the email.",
    "modal_forgot_btn": "Confirm",
    "forgot_email_send": "The verification e-mail has been sent.",
    "forgot_email_send_error": "Failed to send e-mail",
    "verify_success": "Successfully verified",
    "verify_fail": "Failed to verify",
    "verify_success_tip": "Verification is complete. Thank you.",
    "verify_fail_tip": "Invalid link or verification timed out",
    "btn_signin_retry": "Log in again",
    "signup_intro_title": "You Create, \nWe Navigate. ",
    "signup_intro_revenue": "Maximize your Revenue",
    "signup_intro_revenue_subtitle": "Maximize Revenue on 8 Global Platforms \nwith 5 Billion MAU",
    "signup_intro_operation": "Safe & Smart Operations",
    "signup_intro_operation_subtitle": "AI-Driven Operation: \nFrom Content Optimization to Auto Distribution",
    "signup_create_account": "Create an account",
    "signup_agree_tips": "By sign up you agree to our {0} and {1}",
    "signup_agree_tips_terms": "Terms of Use",
    "signup_agree_tips_privacy": "Privacy Policy",
    "account_exist": "The account is already registered",
    "signup_age_14": "I am over 14 years old.",
    "signup_user_protocol": "adobaro User Agreement",
    "signup_privacy_protocol": "adobaro Privacy Policy",
    "signup_marketing_protocol": "(optional) Marketing Consent Form",
    "signin": "Log in",
    "signin_tip": "{0} if you already have an account.",
    "signup_email_send": "A verification e-mail will be sent to the registered e-mail address.\nSet up your password after the verification is complete.",
    "signup_email_send_error": "Failed to sign up",
    "password_intro_title": "Enter Chinese \nPlatforms",
    "password_set": "Set up new password",
    "password_reset": "Reset password",
    "password_set_subtitle": "You have been verified through e-mail. Please set up a secure password.",
    "password_reset_subtitle": "You have been verified through e-mail. Please set up a new secure password.",
    "btn_password_set": "Set Up Password",
    "btn_password_reset": "Reset password",
    "password_set_success": "Successfully set up password",
    "password_reset_success": "Successfully reset password",
    "password_set_fail": "Failed to set up password",
    "password_reset_fail": "Failed to reset password",
    "password_set_code_invalid": "The verification code has expired.\nPlease apply again.",
    "password_reset_code_invalid": "The verification code has expired.\nPlease apply again."
  },
  "userAgreement": {
    "title": "adobaro User Agreement",
    "updated": "Updated: October 31, 2023 ",
    "effective": "Effective: October 31, 2023",
    "introduction": "[Prelude and Introduction]",
    "introduction_1": "adobaro User Agreement (hereinafter referred to as “the Agreement”) is a legal agreement between a user and adobaro on use of adobaro services. adobaro refers to adoba cultural media (Sichuan) Co., LTD. (安哆巴文化传媒（四川）有限公司) and its affiliated companies (hereinafter referred to as “the Company”). ",
    "introduction_2": "In this Agreement, provisions closely concerning a user’s interests (when appropriate, including provisions on exemption of adobaro from any liability, restrictions on a user’s right, dispute settlement, jurisdiction, etc.) are set forth in bold for users’ special attention. Before using any adobaro service, a user shall carefully read, fully understand and accept without reservation all provisions hereof.",
    "introduction_3": "adobaro’s Privacy Policy, Help Center, and other agreements, specifications, rules, statements, announcements and policies, among others, published by adobaro (hereinafter collectively referred to as “Other adobaro Rules”), which form an integral part of this Agreement, shall be complied with likewise by a user upon use of adobaro services.",
    "introduction_3_bold": "By accepting and consenting to this Agreement, a user accepts and consents to Other adobaro Rules, vice versa.   ",
    "introduction_4": "As a user, you shall make sure that you have full capacity for civil rights and civil conduct, and you are capable of consenting to and complying with this Agreement and of independently assuming legal liability for all your acts hereunder. If you have no such capacity for civil conduct to adapt your acts as mentioned, you are required to procure knowing approval from your legal guardian. If you are a minor, you shall read and determine whether to consent to this Agreement in the company of your legal guardian, pay special attention to provisions relating to minors, and by exercising rights and performing obligations hereunder, you will be deemed to have procured approval from your legal guardian. ",
    "content_1": "Service description ",
    "content_1_1": "Services available from adobaro include nonexclusively:  ",
    "content_1_1_1": "adobaro website: www.adobaro.com (and any other website operated by adobaro, or affiliated website or software relying on any signed website or software); ",
    "content_1_1_2": "All terminal client products directly operated or jointly operated with a third party by adobaro, including but not limited to PCs, tablets, mobiles, TV sets, set-top boxes, wearable devices, in-car tablet devices, smart speakers, and so on; ",
    "content_1_1_3": "adobaro user material management, content management and platform management; ",
    "content_1_1_4": "Servers and network storage space directly possessed or operated by adobaro;",
    "content_1_1_5": "Various value-added services available from adobaro; ",
    "content_1_1_6": "Other technologies and/or services available from adobaro, depending on the actual availability made by adobaro and subject to addition, closure or other adjustment by adobaro at its sole discretion. ",
    "content_1_2": "Services available from adobaro are only intended for use on the platforms set forth in the preceding Clauses 1.1.1 and 1.1.2 (hereinafter referred to as “adobaro-related platforms”). Any service provided by adobaro, if separated from such adobaro-related platforms by malicious cracking or other illegal means, shall no longer be deemed as a service provided by adobaro hereunder, and the person responsible for such separation shall be held liable for all consequences resulted therefrom, while adobaro reserves the right to pursue legal liability of such responsible person. Services provided by adobaro are intended for non-commercial use. Without prior written consent of adobaro, none of such services may be used for any commercial purpose. ",
    "content_1_3_bold": " Means published officially by adobaro for registration, login, downloading of a client (including but not limited to iOS, Android and other smart platforms) and use of adobaro services are the only legal means for such purposes. Obtaining adobaro services by any other means, channel or way (including but not limited to account, client downloading, etc.) will be considered as illegal; such act will not be admitted by adobaro, and once it is found, adobaro may immediately delete, clear or block the account concerned, with all adverse outcomes incurred thereby for the user’s own account.",
    "content_1_3": " As a user, you shall keep your account and password properly, use a strongly secure password and take care to avoid account leakage or theft. In no event shall adobaro be held liable for any loss caused by leakage or theft of your account.",
    "content_1_4": " adobaro may modify, replace or upgrade any software relating to adobaro services, provided an announcement is made to users in advance (in the form of a pop-up announcement, an announcement on the homepage of the website, a system message, or the like). ",
    "content_1_4_bold": "If you disagree or do not accept such modification, replacement or upgrade, you may directly decline, stop and cancel the use; otherwise continuing use of the adobaro services will constitute your agreement and acceptance regarding such modification, replacement or upgrade; such agreement and acceptance are bound by this Agreement. ",
    "content_1_5": " A “user” herein refers to a user that has registered to obtain an adobaro account (hereinafter referred to as “registered user”) or a user that has not registered to obtain an adobaro account (hereinafter referred to as “unregistered user”). Any product and/or service user that has not registered with adobaro shall automatically become an “unregistered user” immediately upon downloading or installing an adobaro product and/or using an adobaro service. ",
    "content_1_6": "Special attention: This Agreement provides general user service terms uniformly applied by adobaro; yet the wide range of adobaro’s products and services and provision of different products and services to different users may necessitate formulation of a special user service agreement to set forth specific details and rules of providing a specific product/service to a specific user. To use such specific product/service, you shall thoroughly read and accept all provisions of such special agreement before use. ",
    "content_2": "Registration for an account",
    "content_2_1": "Some adobaro services are only available to a registered user. If you use the network storage space provided by adobaro to upload and disseminate any audiovisual program, text, fine art, photograph or other content, you need to become a registered user following the rules and procedure set forth in this Agreement and Other adobaro Rules, and make sure the registered information is true, correct and complete. If any change occurs to the foregoing registered information, such change shall be timely updated.",
    "content_2_2": "You shall take full responsibility for all acts performed with the adobaro account ID you have obtained by registration (hereinafter referred to as “the account ID”), and abide by laws and regulations of the People’s Republic of China (hereinafter referred to as “China” or “the country”), as well as social morality and the principle of information authenticity; you shall not use any illegal and harmful information, nor falsely use any other person’s name, portrait or designation for or otherwise confuse the account ID, nor infringe legitimate rights and interests of any subject, including but not limited to adobaro. ",
    "content_2_3": "You shall understand and agree that you are only entitled to use your account ID and virtual products and services provided by adobaro for your account ID; the account ID and the virtual products and services are property of adobaro (unless otherwise specified by laws and regulations). Without written consent of adobaro, you may not dispose (by gifting, lending, transferring, selling, mortgaging, causing to inherit or allowing any other person to use) the right of use to the account ID.",
    "content_2_3_bold": "If adobaro finds or has reasonable cause to believe that the user of your account ID is not the initial registration applicant of the account ID, adobaro may, without giving a notice, provisionally discontinue or cease provision of services for the account ID and deregister the account ID; any loss of content or virtual property incurred thereby shall be for your or the account ID user’s part.",
    "content_2_4": "You shall keep your account ID information, account password and other information or data relating to your account in proper custody. If any change, loss or property damage occurs in relation to information and data of your account, you shall immediately inform adobaro and act to assume corresponding legal consequences. ",
    "content_2_5": "If you log in with an adobaro account ID via a third-party website or service, which, unless otherwise specially indicated, is not operated by adobaro, you shall discretionarily judge the security and availability of such third party website, and solely assume relevant risks and responsibilities",
    "content_2_6": "You agree and undertake that: ",
    "content_2_6_1": "You will immediately and effectively inform adobaro if your account ID or password becomes subject to any unauthorized use or security threat;",
    "content_2_6_2": "You will log out of relevant account in a secure manner after each login to adobaro or use of relevant services",
    "content_2_6_3": " You accept to receive notices and other relevant information from adobaro through email, client, webpage or other legal means.",
    "content_2_7": "You agree and undertake not to: ",
    "content_2_7_1": "Falsely use any other person’s information to register an adobaro account of your own;",
    "content_2_7_2": " Register an adobaro account in the name of any other person without legal authorization from such other person;  ",
    "content_2_7_3": " Register multiple adobaro accounts with the same identity authentication information (including multiple adobaro accounts under the control of the same person according to review and verification by adobaro); ",
    "content_2_7_4": " Steal or have unauthorized use of any other person’s adobaro account, member ID, etc.; ",
    "content_2_7_5": "Register an adobaro account by using any abusive, libelous, obscene or political word in violation of laws of the People’s Republic of China, morality, public order and good custom; ",
    "content_2_7_6": "Make ill-intentioned use of network vulnerabilities by proper or improper means for the purpose of illegally possessing relevant service resources of adobaro;",
    "content_2_7_7": "Encroach on legitimate rights and interests of any other person. ",
    "content_2_8": "You understand and agree that if you breach any of the foregoing provisions, adobaro may forbid your registration and/or block your account.",
    "content_2_9": "Considering that services provided by adobaro include provision of an access via adobaro to third-party Internet operation platforms in China (each hereinafter referred to as a “Chinese platform” or “platform”), your special attention is drawn to the following: ",
    "content_2_9_1": " To ensure consistent affiliation of your login ID and corresponding accounts with such platforms, you must use your personal mobile number as your account ID for access to the platforms;",
    "content_2_9_2": " adobaro merely supports application for access to platforms available on adobaro; if a platform is unavailable on adobaro, it means adobaro does not support the platform currently, nor takes relevant responsibility for operation of the platform. ",
    "content_2_9_3": " To obtain access to a platform via adobaro, you need to personally submit an application following the application procedure, and such application will be considered by adobaro as your application in person; by submitting the application, you agree that adobaro may use your mobile number in application for registration with the platform; ",
    "content_2_9_4": "Chinese platforms may update their operating rules from time to time. adobaro will, after becoming aware of such update, timely inform you of the updated rules. You shall pay attention to notices on adobaro to avoid any loss resulted from any change to such operating rules.",
    "content_2_9_5": "Before getting access to a Chinese platform, make sure your mobile number is subject to permanent use. If your mobile number has been used on adobaro for access to the platform but it is no longer in use or cannot be recovered, making it impossible for you to log into the platform, or corresponding account becomes void due to long-time disuse or for any other reason, any loss incurred thereby (including loss of the account with the platform and loss of any virtual property) shall be for your own account; if you apply for access to the platform with a new mobile number, such new mobile number will correspond to a new account without any conflict or intersection with the former mobile number.",
    "content_2_10": "You know and understand that an account ID allows you to access all products available in the adobaro system.",
    "content_2_11": "You understand and agree that adobaro may, to the maximum extent permitted by law, decide at its sole discretion to recover the right of use to an account ID without giving further notice to the user or procuring consent of the user. ",
    "content_2_12": "You agree that adobaro may automatically synchronize your account ID with this website and all contents you publish by using the account ID for authorized display in the name of your account ID to other software and websites operated by the Company and/or its affiliated companies and/or its controlling company, and that the Company or its controlling company may display the same in proper forms in other software and websites. Your releases, modifications and deletions will also be synchronized to the foregoing other software and websites. When registering with or logging into any other software product and website (if present) operated by the Company and/or its affiliated companies and/or its controlling company by using a registered account ID or a third-party software user account ID, you shall abide by the User Agreement of such other software and website and provisions of other applicable agreements. For the avoidance of doubt, you acknowledge and agree that your account ID with this website and all contents you publish via the account ID will be fully or partially synchronized to client applications and websites for which the Company provides content services or has applied for access to a platform. This is a free, irrevocable and exclusive authorization without geographical restrictions.",
    "content_2_13": "You understand and agree that to make full use of account ID resources, adobaro will operate following rules of each platform. ",
    "content_3": "Contents on adobaro",
    "content_3_1": "Contents on adobaro refer to any information, data, text, software, music, audio, picture, graph, video, message, link or other material uploaded, posted or otherwise transmitted or disseminated, whether publicly or privately, through services provided by adobaro (hereinafter referred to as “contents”). Any person who provides or uploads any of such contents shall be fully responsible for the contents so provided or uploaded, ",
    "content_3_1_bold": "and shall, if any damage is incurred thereby, indemnify adobaro for such damage. If a third party claims any right against adobaro regarding any contents uploaded provided by a user, adobaro may directly delete, shield or unlink such contents or take other necessary measures without giving prior notice to the use",
    "content_3_2": " As a user, you warrant that you have legal copyright or corresponding authorization in relation to contents you upload to or publish on adobaro, so that adobaro may display, disseminate or promote such contents. ",
    "content_3_3": "adobaro will additionally provide you with worldwide display and promotion services. For this, you authorize adobaro to display all contents published with your account ID on products operated by adobaro independently or jointly with a third party; such products include, without limitation, PCs, tablets, mobile phones, TV sets, set-top boxes, wearable devices, in-car tablet devices, smart speakers, and all other client software and/or websites. Besides, you also allow adobaro to process the contents or to have the content processed by a third party as necessary (such processing includes, without limitation, translation, compilation and adaptation). You hereby acknowledge and agree that adobaro may act on its own or engage a third party to properly develop and use the above contents (fully or partially) in any publicity, promotion and/or research in relation to such contents, adobaro’s products and corresponding services, adobaro and/or adobaro brand. For the avoidance of doubt, you understand and agree that the above contents include personal images, portraits, names, trademarks, service markings, brands, designations, signs and company logos you possess or you are permitted to use and incorporate in such contents.  ",
    "content_3_4": " As it is impossible for adobaro to foresee and reasonably control contents to be uploaded via adobaro services, you may see some unpleasant, offensive or aversive contents when using adobaro services. In this respect, you agree to waive any right of recourse that may arise therefrom. Nevertheless, adobaro may legally cease to transmit any of such contents and take corresponding measures, including but not limited to ceasing your access to any part or all services of adobaro, keeping relevant records and reporting to competent departments.",
    "content_4": "Rules for use ",
    "content_4_1": "When using adobaro services, the user shall abide by the following laws and regulations: ",
    "content_4_1_1": "Law of the People’s Republic of China on Keeping Confidentiality of State Secrets; ",
    "content_4_1_2": "Copyright Law of the People’s Republic of China; ",
    "content_4_1_3": " Regulation of the People’s Republic of China for Security Protection of Computer Information Systems; ",
    "content_4_1_4": "Regulation for Protection of Computer Software; ",
    "content_4_1_5": "Regulation for Ecological Governance of Network Information Content",
    "content_4_1_6": "Regulation for Protection of the Right to Disseminate Information over Networks",
    "content_4_1_7": "Cybersecurity Law of the People’s Republic of China; ",
    "content_4_1_8": "Other laws, regulations, rules, ordinances and specifications of legal effect. ",
    "content_4_2": "You may not: ",
    "content_4_2_1": " Produce, upload, reproduce, transmit or disseminate anything that is banned by laws or statutory regulations or is otherwise offensive, including but not limited to information, data, texts, software, music, pictures, graphs, messages or other materials that go against the basic principles established by the Constitution, threaten the national security, betray state secrets, tend to overthrow the regime, undermine the national unification, impair national unity, cause harm to national honors and interests, incite national hatred or ethnic discrimination, breach national religious policies, advocate any heresy or superstition/obscenity/porn/gambling/violence/murder/terror, abet crimes, abuse or defame any other person, or encroach on any other person’s legitimate rights and interests; ",
    "content_4_2_2": " Cause danger to any minor in any way; ",
    "content_4_2_3": "Pretend to be any person or agency, or falsely claim or cause to misbelieve presence of any relationship with any person or agency; ",
    "content_4_2_4": " Falsify titles or otherwise manipulate identification of data so as to make people misbelieve that the content is transmitted by adobaro; ",
    "content_4_2_5": " Upload, post, email or otherwise transmit any unauthorized content (such as internal data, confidential data, etc.);",
    "content_4_2_6": "Upload, post, email or otherwise transmit any content that infringes any person’s patent, trademark, copyright, trade secret or other intellectual property rights; ",
    "content_4_2_7": "Upload, post, email or otherwise transmit advertising letters, promotional materials, “junk mails”, etc.; ",
    "content_4_2_8": "Track or otherwise disturb any other person; ",
    "content_4_2_9": "Upload, post, email or otherwise transmit any software virus, other computer code, file or program data that may disturb, destroy or limit functions of any computer software, hardware or communication device;  ",
    "content_4_2_10": "Interfere with or disturb adobaro services or any server or network connected for adobaro services, or refuse to comply with this Agreement; ",
    "content_4_2_11": "Act on your own or engage any other person to or help any other person procure services, contents and data from the platform in any way (including but not limited to use of any robot software, crawler, or automated program, script or software) without prior express written permission of adobaro; ",
    "content_4_2_12": " Upload any content in violation of relevant regulations of the National Radio Television Administration, including but not limited to Regulation on Management of Internet Audiovisual Program Services (NRTA Regulation No. 56); uploaded programs shall conform to laws, statutory regulations and departmental rules; what is uploaded shall not contain anything that: ",
    "content_4_2_12_1": "opposes the basic principles established by the Constitution;",
    "content_4_2_12_2": "undermines the national unification, sovereignty or territorial integrity;",
    "content_4_2_12_3": "reveals any state secret, endangers state security or causes harm to national prides and interests;",
    "content_4_2_12_4": "incites national hatred or ethnic discrimination, destroys the national unity, or infringes folk customs;",
    "content_4_2_12_5": "advocates any heresy or superstition;",
    "content_4_2_12_6": "disturbs the social order or breaks the social stability;",
    "content_4_2_12_7": "advocates obscenity, gambling, violence, or abets crimes;",
    "content_4_2_12_8": " insults or defames others, or infringes any other person's privacy or other legitimate rights and interests;",
    "content_4_2_12_9": "causes harm to social morality or outstanding national cultural traditions;",
    "content_4_2_12_10": "is prohibited by laws, statutory regulations and national provisions.",
    "content_4_2_13_bold": "Produce, release or disseminate, based on deep learning, virtual reality and other new technologies/applications, false news or information that is prohibited by laws and regulations. Upon releasing or disseminating untrue audio and video information based on deep learning, virtual reality and other new technologies/applications, you shall identify such information in a remarkable manner; otherwise adobaro may take measures against relevant content and account, including but not limited to adding identification, limiting or blocking the account, etc. ",
    "content_4_2_13": "All in all, contents produced, released or disseminated shall conform to the Regulation on Management of Network Audio and Video Information Services, other applicable laws and regulations, as well as rules released by adobaro for creation. ",
    "content_4_2_14": "A user who uploads anything containing information or content in violation of policies, laws and regulations as mentioned above will be held directly liable for all adverse outcomes arising therefrom; where any adverse impact is incurred on adobaro, the user shall also take responsibility to eliminate the impact, and make indemnification for all losses caused thereby.  ",
    "content_4_3": "No user shall release any information carrying words relating to the “adobaro” brand (such as “in cooperation with adobaro”, “produced jointly with adobaro”, etc.) through any channel or media (including but not limited to we-media, etc.), or mention the relationship with adobaro or discretionarily use the adobaro brand in promotion. For publicity of a program in cooperation, it is only allowed to mention the program itself. All “adobaro”-carrying publicity materials to be released by a user shall be subject to written agreement between corresponding departments in cooperation; otherwise, the user shall be held liable for compensating all losses incurred thereby to adobaro. ",
    "content_4_4": "Paid Plans",
    "content_4_4_1": "Users who sign up for a paid plan on adobaro.com confirm that they have read and fully understand the user agreement. They also agree to make monthly or annual payments as designated for their respective paid plans.",
    "content_4_4_2": "Users can use paid plans for free during the initial 1 month. To avoid being billed for the next installment under their particular paid plan, users must cancel the paid plan before the 1-month free trial period ends. The duration of the free trial may change or be canceled in accordance with adobaro's internal policies.",
    "content_4_4_3": "Paid plans are non-refundable and non-extendable, even if users do not use adobaro's service during the period covered by their particular plan.",
    "content_4_4_4": "If you sign up for a paid plan, you will be charged the first recurring payment on the date of the registration for the paid plan, and you will then be charged on a recurring basis monthly or annually until you cancel the paid plan. You acknowledge and agree that you are solely responsible for canceling of the paid plan. adobaro does not accept cancellation through customer service or email. You may cancel a paid plan at any time in your account settings.",
    "content_4_4_5": "Service will remain available until the end of your subscription period, even if you cancel your paid plan earlier.",
    "content_4_4_6": "Monthly and annual fees are non-refundable. You must cancel a paid plan before it renews to avoid billing of the next installment under your particular paid plan.",
    "content_4_4_7": "Benefit and Pric",
    "content_4_4_8": "The benefits of each plan can be changed or added depending on the internal policy.",
    "plan_project": "Category",
    "plan_free": "Free",
    "plan_basic": "Basic",
    "plan_pro": "Pro",
    "plan_original": "A fixed price",
    "plan_price": "Price",
    "plan_discounts": "A discount rate",
    "plan_discounts_prices": "A discounted price",
    "plan_special_offer": "Promotion",
    "plan_month": "Monthly subscription",
    "plan_year": "Annually subscription",
    "plan_special_des": "30 days free for subscription\n(Limit once per account)",
    "plan_content_filter": "Pre-inspection of content",
    "plan_open_platform": "Number of channels open",
    "plan_upload_count": "Number of uploads available",
    "plan_upload_limit_free_1": "Once a week",
    "plan_upload_limit_free_2": "PASS Authorized Users:",
    "plan_upload_limit_free_3": "Twice a week",
    "plan_upload_limit_basic": "Once a day per platform",
    "plan_upload_limit_pro": "Unlimited",
    "plan_upload_limit_pro_a": "(Up to 5 times a day per platform)",
    "plan_upload_store": "Limit one-time upload capacity",
    "plan_copyright": "Copyright protection",
    "plan_charge": "Settlement fee",
    "plan_storage": "Offered Storage",
    "plan_profitability": "Settlement available",
    "plan_account": "Channel Ownership Recognition",
    "plan_chinese_title": "Automatically optimize Chinese titles",
    "plan_cover": "Automatically optimize thumbnails",
    "plan_tag": "Automatically optimize hashtags",
    "plan_appointment": "Schedule upload function",
    "plan_upload_simul": "Upload each platform simultaneously",
    "plan_plus_v": "Provide channel v authentication",
    "content_4_5": "Using Points",
    "content_4_5_1": "While using the adobaro service, points will be awarded under certain conditions. These points cannot be exchanged for cash and can only be used for some services provided on adobaro.",
    "content_4_5_2": "The points awarded and their usage can be adjusted based on adobaro's operational policies.",
    "content_4_6": "Cancellation and Refund of Paid Plans",
    "content_4_6_1": "Users can cancel their paid plan at any time. Upon cancellation, the auto-renewal of the plan will be stopped.",
    "content_4_6_2": "When a paid plan is canceled, no further charges will be applied, but the benefits of the plan remain until the end of the billing cycle.",
    "content_4_6_3": "No refunds will be provided for the period between the cancellation date and the end date of the paid plan.",
    "content_4_6_4": "No refunds will be provided if the service is canceled after 14 days from the payment, and the service will continue until the end of the contract period. ",
    "content_4_6_5": " While no refunds will be provided after 14 days from payment, upon cancellation, no further charges or renewals will occur after the expiration of the remaining paid planduration.",
    "content_5": "Third party link",
    "content_5_1": "adobaro services may provide links to other international websites or resources. Unless otherwise stated, adobaro has no control over any third party website, and thus shall by no means be held liable for any loss or damage incurred to a user for using or relying on the foregoing websites or resources. Before you leave adobaro to access such other websites or resources, you are recommended to carefully read service terms and privacy policies of such other websites or resources.",
    "content_6": "Intellectual property rights ",
    "content_6_1": "Subject to protection by the Universal Copyright Convention, the Copyright Law of the People’s Republic of China, the Patent Law, and other laws and regulations in relation to intellectual property rights, all intellectual property rights pertaining to adobaro services and to any software, technology, trademark and material used in the services are vested in and possessed by adobaro. “Intellectual property rights” include any and all rights, titles and interests set forth in the Patent Law, the Copyright Law, the Trademark Law, the Anti-unfair Competition Law and other relevant laws and regulations, as well as any and all applications, updates, extensions and restorations therein.",
    "content_6_2": "No user shall modify, adapt or translate software, technology, materials, among other things, used in adobaro services, or develop any derivative products therefrom, or seek to obtain corresponding source codes through reverse engineering, decompilation, disassembly or other similar acts; otherwise all legal outcomes shall fall within the user’s responsibility, and adobaro may pursue legal liability of the breaching party. ",
    "content_6_3": "No user shall maliciously modify, copy or disseminate software, technology, materials, among other things, used in adobaro services. Otherwise the user shall undertake to cover any damage incurred thereby to any other person, and shall, if any damage is caused to the corporate image of adobaro, assume corresponding legal liability. ",
    "content_6_4": " No user shall delete, conceal or change the copyright, trademark or other right statements of adobaro without authorization. All design drawings, other drawings, product names and service names possessed by adobaro are trademarks and markings of adobaro, and may not be used or reproduced by anyone for any other purpose. ",
    "content_6_5": " adobaro is fully entitled to intellectual property rights pertaining to contents developed independently on its own and exclusively obtained by it through authorization. Without permission of adobaro, no individual or organization may privately reprint, disseminate, display for view or otherwise encroach on such intellectual properties. Otherwise adobaro will pursue legal liability of the violating person.  ",
    "content_6_6": " Intellectual property rights vested in and possessed by adobaro will not be transferred by virtue of any use. ",
    "content_7": "Disclaimer ",
    "content_7_1": "adobaro makes no representation or warranty regarding the correctness or reliability of any content, information or advertisement included in, received through or by linking, unloaded from or otherwise acquired in connection with the network services, and accepts no warranty for any product, information or data procured or acquired through advertisement or display via the services. Risks pertaining to use of the services shall be all for the users’ part. ",
    "content_7_2": "Unless otherwise expressly specified in writing, all products and services made available by adobaro to users are provided on",
    "content_7_3": "adobaro makes no guarantee with respect to the following (including but not limited to): ",
    "content_7_3_1": "adobaro cannot assure that websites, clients and other software provided by it, though after such websites, clients and other software have passed adobaro’s tests, are fully compatible with other hardware, software and systems due to limitation in technology. Any user who comes across an incompatibility problem may report to adobaro, so as to seek for technical support. If the problem cannot be solved, the user may elect to uninstall or cease use of the adobaro services. ",
    "content_7_3_2": "Use of adobaro services requires access to Internet services, and thus may be affected by uncertainties at different procedures. Any of force majeure, hacker attack, system instability, network interruption, user shutdown, communication line and other factors may lead to interruption of adobaro services or failure to satisfy users’ requirements. adobaro makes no warranty with respect to the fitness of adobaro services for use required by any user. ",
    "content_7_3_3": "As the client and other software provided by adobaro may be downloaded or disseminated via network, adobaro cannot assure that relevant software downloaded from any other website than that officially designated by adobaro or acquired through any other channel than that designated by adobaro is free from any computer virus, disguised Trojan program or hacker software, nor shall assume any legal liability for all direct or indirect damages incurred thereby to users. ",
    "content_7_3_4": "adobaro provides no security with respect to the safety, reliability, timeliness and performance of adobaro services and products. ",
    "content_7_3_5": " adobaro makes no warranty that any product, service or other material provided by it can meet any user’s expectation. ",
    "content_7_4": "Where you download or acquire any information via adobaro services, you shall solely undertake to bear relevant risks, and be held fully liable for any computer system damage or data loss due to use of such information. ",
    "content_7_5": "adobaro shall not be held liable for any direct, indirect, incidental, consequential or punitive compensation for loss of profit, good will or data, or other tangible or intangible loss as a result of the following: ",
    "content_7_5_1": "Unavailability of all or partial adobaro services; ",
    "content_7_5_2": " Any product, data or service purchased or acquired via adobaro services; ",
    "content_7_5_3": "Unauthorized use or modification of user information; ",
    "content_7_5_4": "Other matters in connection with adobaro services. ",
    "content_7_6": "You shall take good custody of your account ID and password, protect the password security, and guard against account leakage or theft. adobaro shall in no circumstance be held liable for compensating you for any loss resulted from account ID leakage or theft, or for loss or impairment of property held under the account ID as a result of communication line failure with relevant telecommunication and Netcom departments, network or computer failure, system instability, force majeure (such as server crash) or any other factor other than by reason of adobaro.",
    "content_7_7": "You understand and agree to discretionarily download and use free adobaro services, and undertake to assume risks pertaining thereto, including but not limited to risks pertaining to your behaviors during use of the adobaro services and all outcomes from use of the adobaro services. Any computer system damage or data loss as a result of downloading or using adobaro services shall be all for your own account.",
    "content_7_8": "adobaro shall by no means be held legally liable for unrecoverable loss or impairment of an account ID or any property held under the account ID as a result of false authentication information you provide. ",
    "content_7_9": "adobaro will make public announcements in advance when relevant platform services need to be discontinued for system maintenance, upgrading or other reasons. adobaro shall by no means be held liable for any inconvenience or loss incurred during the discontinuation of services as a result of hardware failure or other force majeure, for any information loss and/or other outcomes due to any adjustment by adobaro, and for any inconvenience or loss resulted from discontinuation of services by any Chinese platform in which case adobaro will timely inform you of any notice from the platform in advance. ",
    "content_8": "Service change, interruption and termination",
    "content_8_1": "You understand and agree that adobaro may change, interrupt, discontinue or terminate the services based on adjustments to its operating strategies. ",
    "content_8_2": "You understand and agree that adobaro may transfer relevant assets under the services to a third party upon occurrence of merger, division, acquisition or assets transfer, and that adobaro may, by unilaterally giving you a notice, have any part or all of the services operated and corresponding rights and obligations performed by a third party. ",
    "content_8_3": "You understand and agree that if you breach any law, regulation, this Agreement and Other adobaro Rules, social morality, public order and good custom and/or infringe legitimate rights and interests of other persons during use of the adobaro services, adobaro may unilaterally discontinue or terminate provision of all or any part of the services to you without giving a notice.",
    "content_8_4": "You understand and agree that if you act in any way against laws, regulations, social morality, public order and good custom and/or infringe the legitimate rights and interests of any other person, thereby affecting or threatening to affect the reputation, good will or other legitimate rights and interests of adobaro and/or other persons, adobaro may unilaterally discontinue or terminate provision of all or any part of the services without giving a notice.",
    "content_8_5": " adobaro may, after terminating provision of services to you, delete or anonymize your personal information as required by applicable laws; adobaro may also continue retaining other content and information you have left with the platform for such term and in such manner specified by legal provisions. ",
    "content_9": "Deregistration",
    "content_9_1": "You may apply to adobaro for account deregistration by contacting the Customer Services of adobaro (unless otherwise specified by laws, regulations, in this Agreement or in any other rules of adobaro). ",
    "content_9_2": "Special attention: After deregistration of your adobaro account, you will no longer be able to log in with the account ID to use any adobaro product or service or services in cooperation with any third party as incorporated in adobaro products and services; meanwhile, adobaro will terminate provision of products and services for your account, which will inevitably bring inconvenience for you to seek after-sales right protection. Your adobaro account may not be recovered once deregistered, so do consider carefully before you proceed to deregister your account.",
    "content_9_3": "If you are determined to deregister your adobaro account, make sure you are fully aware and understand that corresponding UID will still exist after deregistration of the account, but you will no longer have rights and interests pertaining to the account. Such rights and interests include nonexclusively the following: ",
    "content_9_3_1": "After deregistration of your account, you will no longer be able to log in with the account ID to use any adobaro product or service; ",
    "content_9_3_2": " After deregistration of your account, all content, information, data and records in relation to your previous login with the account ID and use of all products and services provided by adobaro through the account will be deleted or anonymized, and you will no longer be able to search, access, acquire, use or retrieve information including but not limited to account information (avatar, nickname, signature, etc.) and other binding information in relation to previously procured access to the platform; ",
    "content_9_3_3": "After deregistration of your account, transaction records of the account will be irrecoverably cleared; ",
    "content_9_3_4": "After deregistration of your account, adobaro may cease provision of any service for you in relation to the account; ",
    "content_9_3_5": "By deregistering your account, you agree to give up interests that have arisen during use of adobaro’s products and services through the account and have not been fully consumed, and interests that are expected to arise in future in connection with the account. adobaro will clear all interests under the account, including but not limited to your yet-to-expire interests as a major member, any unused coupons, any virtual money and prop owned by your game role, your membership interests in products and/or services of adobaro, any purchased but yet-to-expire online service, other interests that have arisen but have not been fully consumed or interests that are expected to arise in future.",
    "content_9_4": "To protect your account security, property rights and interests, you need to check and verify, before you apply to us for deregistering an adobaro account, that the account of which you are applying for deregistration has satisfied the following conditions, including but not limited to:  ",
    "content_9_4_1": "The account has been registered through an official channel; it conforms to this Agreement and other relevant regulations; and it is a registered user account of your own; ",
    "content_9_4_2": "Deregistration is performed following the deregistration procedure required by the Customer Services; ",
    "content_9_4_3": "The account is in the secure status, which means it is not in a condition of application for password retrieval or modification of the mobile number, or it is subject to no pending complaint or reporting, or it is not in any unsecure/abnormal status; ",
    "content_9_4_4": "There is no pending transaction in the account; ",
    "content_9_4_5": "There is no cash withdrawal that has been initiated but is yet to be completed in the account;",
    "content_9_4_6": " The account has been in no contract relationship with any adobaro operation or yet-to-expire contract term (of a contracted anchor, etc.); ",
    "content_9_4_7": "If the account has gained access to other services, the access to such other services has been cancelled; ",
    "content_9_4_8": "Other conditions required to be satisfied.",
    "content_9_5": "Once you have fully read and understood the provisions of Article 9.2 and 9.3, and verified satisfaction of all conditions in Article 9.4, you may apply to the Customer Services for deregistration of your account. The Customer Services will review the security status of your account and use of relevant products and services by the account to generally determine whether your account has met the specified conditions. ",
    "content_9_6": "Once your adobaro account is deregistered, relevant user agreement and other documents made by you and us to provide rights and obligations shall terminate accordingly (unless otherwise agreed or specified by laws). You know and agree that deregistration of your account will not relieve or discharge you from relevant responsibilities that you are required or may be required to assume according to relevant laws, regulations, relevant agreements and rules, that after deregistration of your account, all accounts you have registered with Chinese platforms by using your mobile number will be deregistered, and you will no longer be able to publish your content on the Chinese platforms via adobaro, and that after successful deregistration of your account, no earnings you have in your adobaro account can be cashed, any loss of earnings incurred thereby to adobaro and to the account with the platform will be for your own account, and no refund or indemnification will be made by adobaro. ",
    "content_10": "Privacy policy ",
    "content_10_1": "adobaro attaches great importance to protection of users’ personal information and privacy. Personal information refers to information recorded by electronic or other means and used, whether separately or in combination with other information, to identify a specific natural person or reflect activities of such natural person. When you download, install, start, browse, register, log in or use adobaro products and/or services, adobaro will process and protect your personal information as agreed in adobaro’s Privacy Policy published on the platform. You shall carefully read through and fully understand adobaro’s Privacy Policy, and when necessary, make selections as you think appropriate following the guide of adobaro’s Privacy Policy.",
    "content_10_2": "You shall carefully read and fully understand the adobaro’s Privacy Policy before using adobaro products and/or services. Your disagreement with the policy may make you unable to normally use adobaro products and/or services or to achieve the expected effect of adobaro services. By using or continuing use of any adobaro product and/or service, you fully understand and accept all content of adobaro’s Privacy Policy (as updated). ",
    "content_10_3": "If you have any question about adobaro’s Privacy Policy or anything about your personal information (including queries, complaints, etc.), you may contact us by using the contact means published in adobaro’s Privacy Policy.",
    "content_11": "Provisions on minors ",
    "content_11_1": "adobaro stresses protection of minors. If you are a minor, do read this Agreement under the guardianship and guidance of your legal guardian, and procure your legal guardian’s approval for use of adobaro’s products and/or services.",
    "content_11_2": "Minors shall follow the guidance of respective legal guardians in guard against security problems during surfing on the Internet. adobaro does not encourage any minor to use adobaro products/services for any consumer behavior; any request for consumption by a minor shall be made in the name of corresponding legal guardian or with express consent from the legal guardian. A minor who uses any adobaro service shall be deemed to have procured his/her legal guardian’s approval in this respect.",
    "content_11_3": "adobaro cautions that minors shall make good use of the Internet for online learning during use of adobaro services, and shall make distinct differentiation between the virtual world and the reality, so as to avoid obsession with the Internet affecting normal student life.",
    "content_11_4": "To protect privacy of minors, adobaro will act strictly in such way and such manner provided in adobaro’s Privacy Policy published on the platform.",
    "content_12": "Governing law and jurisdiction",
    "content_12_1": "The effectiveness, performance, construction and dispute settlement of this Agreement are governed by laws of the People’s Republic of China. If any provision herein becomes invalid due to conflict with any currently applicable law of the People’s Republic of China, the validity of the remainder herein shall not be affected. The parties agree that upon settlement of a dispute, the latest version of adobaro User Agreement accepted by the user shall apply.",
    "content_12_2": "Any dispute arising in connection with this Agreement or implementation of this Agreement shall be settled through friendly consultation whenever possible; where no consensus is reached after consultation, the parties concerned agree to submit the dispute to adobaro’s arbitration committee for arbitration in accordance with its arbitration rules. The arbitration award is final and shall be legally binding on both parties.",
    "content_12_3": "For the avoidance of doubt, this provision on governing law and jurisdiction shall apply to this Agreement and extend to Other adobaro Rules as modified, supplemented and updated, unless otherwise agreed in writing.",
    "content_13": "Amendment and notification",
    "content_13_1": " adobaro may amend this Agreement as necessary based on changes of national policies, technical conditions and product functions, and publish the Agreement so amended. ",
    "content_13_2": "adobaro will alert you to any amendment to the foregoing provisions by proper means (including but not limited to pop-up window, email, private message, public announcement on the website) once such amendment is formally released, providing convenience for you to timely know the latest version of this Agreement.",
    "content_13_3": "It is understood that the title of this Agreement and headings herein are provided for convenience of reference only, and that concurrent presence of two different titles at different locations on our platform where this Agreement is displayed, as it takes time to update this Agreement at all these locations, shall not affect the meaning or interpretation of any provision here",
    "content_13_4": "Any amendment so made shall form an integral part of this Agreement and shall be binding on you. If you have any objection to such amendment, you may immediately stop login and cease use of adobaro and relevant services. By logging in or continuing use of adobaro and relevant services, you will be deemed to have fully read and understood and accept to be bound by the Agreement so amended.",
    "content_13_5": "We will translate this Agreement into other languages for your reference. Such translation is for reference only. Where there is any inconsistency between the translation and the Chinese version, the Chinese version shall prevail."
  },
  "help": {
    "title": "adobaro guide center",
    "update_date": "Update on 31, August, 2023 ver.02",
    "start": "Must-read guide for beginners",
    "adobaro": "What is adobaro？",
    "adobaro_content": "adobaro is a Creator Content Cross-Border SaaS Solution that allows any content creator to easily connect with new fans on more platforms. adobaro simplifies and accelerates all complex processes like channel opening, verification, management, and revenue settlement so that you can focus on creating your content. Cross the border and expand your creativity through adobaro!",
    "plan_title": "Benefits of each plan",
    "plan_subtitle": "The benefits of each plan are as follows.",
    "plan_project": "Category",
    "plan_free": "Free",
    "plan_basic": "Basic",
    "plan_pro": "Pro",
    "plan_original": "A fixed price",
    "plan_price": "Price",
    "plan_discounts": "A discount rate",
    "plan_discounts_prices": "A discounted price",
    "plan_special_offer": "Promotion",
    "plan_month": "Monthly subscription",
    "plan_year": "Annually subscription",
    "plan_special_des": "30 days free for subscription\n(Limit once per account)",
    "plan_content_filter": "Pre-inspection of content",
    "plan_open_platform": "Number of channels open",
    "plan_upload_count": "Number of uploads available",
    "plan_upload_limit_free_1": "Once a week",
    "plan_upload_limit_free_2": "PASS Authorized Users:",
    "plan_upload_limit_free_3": "Twice a week",
    "plan_upload_limit_basic": "Once a day per platform",
    "plan_upload_limit_pro": "Unlimited",
    "plan_upload_limit_pro_a": "(Up to 5 times a day per platform)",
    "plan_upload_store": "Limit one-time upload capacity",
    "plan_copyright": "Copyright protection",
    "plan_charge": "Settlement fee",
    "plan_storage": "Offered Storage",
    "plan_profitability": "Settlement available",
    "plan_account": "Channel Ownership Recognition",
    "plan_chinese_title": "Automatically optimize Chinese titles",
    "plan_cover": "Automatically optimize thumbnails",
    "plan_tag": "Automatically optimize hashtags",
    "plan_appointment": "Schedule upload function",
    "plan_upload_simul": "Upload each platform simultaneously",
    "plan_plus_v": "Provide channel v authentication",
    "plan_tips": "The benefits of each plan can be changed or added depending on the internal policy.",
    "points_title": "What is adobaro points?",
    "points_des": "adobaro points is a bonus concept point prepared to increase the benefits according to the user's activity. You cannot exchange or withdraw points, and you can use points to purchase paid items necessary for channel operation through the point shop.",
    "points_pay_project": "Point Payment Items",
    "points_vip": "Join membership",
    "points_pass": "Global Pass authentication",
    "points_open_platform": "Platform Open",
    "points_upload": "Content upload",
    "points_additional": "achieving 4 missions(Additional)",
    "points_invite": "An invitation to a friend",
    "points_upload_once": "Content upload",
    "points_invite_des": "※ Additional 100p for achieving 4 missions (total 500p)",
    "points_tips_1": "Points paid when uploading are based on one upload regardless of the number of platforms being uploaded.",
    "points_tips_2": "The marketplace  will be introduced soon.",
    "beginner": "Beginner's guide",
    "menu": "Menu introduction",
    "upload": "Upload",
    "upload_des": "adoba.AI optimizes the title and description of the already uploaded/new content to the local language, automatically generates thumbnails suitable for each platform, and uploads them as optimized content.",
    "material": "File storage",
    "material_des": "Storage space to manage your original content like uploaded videos, thumbnails, etc.",
    "content": "Content",
    "content_des": "Manage editing, deletion, and data monitoring of content uploaded to each platform.",
    "platform": "Platform Introduction",
    "platform_des": "View and manage data of opened platform channels.",
    "wallet": "My wallet",
    "wallet_des": "You can check the revenue and points earned through creator activities.",
    "pass": "PASS center",
    "pass_des": "A security verification center that helps users operate adobaro more safely and quickly.",
    "setting": "Settings",
    "setting_des": "Set up your adobaro account.",
    "code": "Mobile verification code",
    "code_tip_1": "A mobile phone number is required to operate a Chinese platform. It is much more secure since your number is private and exclusive to you.",
    "code_tip_2": "There are two stages of verification when opening a platform.",
    "code_tip_3": "After applying, the first verification will take place through your email or on your own service page.",
    "code_tip_3_des": "Please check your spam folder if you have not received an email. The reception is usually quick, but there may be a delay of 3~5 minutes due to the transmission time between mail servers.",
    "code_tip_4": "The second verification will take place through your mobile phone number after the first verification.",
    "code_tip_5": "Your platform opening application is completed once you enter the verification code that you have received into adobaro.",
    "channel": "Where can I check my @channel ID?",
    "youtube_link": "Youtube URL sample",
    "youtube_link_des": "The address may show differently depending on the channel settings. If it is different, please find your channel ID as shown below.",
    "channel_id": "How to find your channel ID",
    "channel_id_tip_1": "Open the YouTube mobile app or web version.",
    "channel_id_tip_2": "Tap or click the profile picture in the top right corner, and the channel ID marked with @ will appear along with your channel name.",
    "channel_id_tip_3": "Copy and paste your channel ID market with @ into the adobaro address bar. ",
    "tiktok_link": "TikTok URL sample",
    "tiktok_link_des_1": "The address after @ in the link is the same as your channel name.",
    "tiktok_link_des_2": "Copy and paste your channel name into the adobaro address bar.",
    "bilibili": "bilibili",
    "alias": "An alias",
    "users": "User base",
    "active_users": "Number of monthly active users",
    "active_creator": "Number of active creators",
    "video_type": "Video mode",
    "view": "Viewed Earnings",
    "specialty": "Characteristics ",
    "bilibili_alias": "Chinese version of YouTube (an alias: B站)",
    "bilibili_users": "Generation MZ 18-35: 80%",
    "bilibili_active_users": "31.5 billion people",
    "bilibili_active_creator": "3.8 million viewers",
    "bilibili_video_type": "Landscape (Main) + Portrate",
    "bilibili_view": "Earn money from 1,000 fans ($3 to $5 per 10,000 views)",
    "bilibili_specialty_1": "* Platform with overwhelming number of young users: Under 24: 75% [as of 2015]",
    "bilibili_specialty_2": "* Use of the bullet (Flying Caption) function: Creating a cybergroup-type viewing atmosphere",
    "bilibili_specialty_3": "*Good environment for virtual creators",
    "bilibili_specialty_4": "*Active features for metaverse",
    "xigua": "Xigua Video",
    "xigua_alias": "Chinese version of YouTube",
    "xigua_users": "25-35 years old: 45 per cent",
    "xigua_users_percent": "The ratio of men and women is similar (54:46)",
    "xigua_active_users": "180 million people",
    "xigua_active_creator": "3.2 million viewers",
    "xigua_video_type": "Landscape",
    "xigua_view": "Profits occur when accumulated more than 17,000 views (per 10,000 views: $3 to $9)",
    "xigua_specialty_1": "*Aiming for a platform with universal tendencies like YouTube",
    "xigua_specialty_2": "*Introduces a personalized content recommendation algorithm, a key competency of byte dance",
    "xigua_specialty_3": "*Ensures a wide user base and diverse content areas",
    "xigua_specialty_4": "* Vlogs and content in the three agricultural (agricultural, rural, and peasant) sectors are more preferred features",
    "xigua_specialty_5": "*Platforms that must be operated with helpers: A single content can dramatically amplify traffic",
    "haokan": "Haokan Video",
    "haokan_alias": "Chinese version of Google",
    "haokan_users": "26-35 years old",
    "haokan_active_users": "110 million people ",
    "haokan_active_creator": "Over 2 million people",
    "haokan_video_type": "Landscape",
    "haokan_view": "Per 10,000 views: $1-1.8",
    "haokan_specialty_1": "* China's largest search engine, Baidu's flagship mid-length video platform",
    "haokan_specialty_2": "*Implements relatively conservative operational policies because they are heavily influenced by the government",
    "haokan_specialty_3": "*If you use it to promote the brand and secure search results rather than the results of the channel itself, good, good!",
    "aiqiyi": "IQIYI",
    "aiqiyi_alias": "Chinese version of Netflix",
    "aiqiyi_users": "A wide range of ages",
    "aiqiyi_active_users": "540 million people",
    "aiqiyi_active_creator": "Over 3 million people",
    "aiqiyi_video_type": "Landscape",
    "aiqiyi_view": "Per 10,000 views: $3-3.5",
    "aiqiyi_specialty_1": "* Video website platform of Baidu, China's largest search engine",
    "aiqiyi_specialty_2": "*Global content producers such as dramas and variety shows",
    "aiqiyi_specialty_3": "*Top 10 mobile apps with the highest traffic in China",
    "aiqiyi_specialty_4": "* Average daily subscription membership: 97 million",
    "aiqiyi_specialty_5": "* Has the largest number of paid subscribers in the world, limited to video services after Netflix",
    "kuaishou": "Kuaishou",
    "kuaishou_alias": "Chinese version of TikTok",
    "kuaishou_users": "High percentage of teenagers under the age of 18",
    "kuaishou_active_users": "600 million people",
    "kuaishou_active_creator": "Over 2 million people",
    "kuaishou_video_type": "Portrate(main)+Landscape",
    "kuaishou_view": "None.",
    "kuaishou_specialty_1": "* Shortform video platform with the second most users in China after TikTok",
    "kuaishou_specialty_2": "*User's content features: Slow and lyrical screens, ordinary daily life, rural life, nature and people",
    "kuaishou_specialty_3": "* Kwai Shou's live commerce sales are high, focusing on cheap and high-quality items",
    "kuaishou_specialty_4": "*Recent trend of increasing influx of young users Equal opportunities for exposure",
    "kuaishou_specialty_5": "-> It's important to upload content often!!!",
    "weibo": "Weibo",
    "weibo_alias": "Chinese version of Twitter",
    "weibo_users": "24–34 years old: 80%",
    "weibo_active_users": "59.3 billion people",
    "weibo_active_creator": "Over 400,000 viewers",
    "weibo_video_type": "Landscape, Portrate",
    "weibo_view": "None. ",
    "weibo_specialty_1": "*Social media platform providing mini-blogging",
    "weibo_specialty_2": "*Influencers such as famous celebrities are often used",
    "weibo_specialty_3": "*Celebs and brand marketing are active",
    "weibo_specialty_4": "*Quick exchange of information based on celebrity fandom culture, public opinion issues, and dissemination",
    "weibo_specialty_5": "*Celebrities entering China's fan club and brand official page are the first to be launched on Weibo",
    "weibo_specialty_6": "*Posts via web pages, WAP pages, and mobile phone applications",
    "weibo_specialty_7": "*Share and interact with photos, videos, or live broadcasts right away",
    "redbook": "Xiaohongshu",
    "redbook_alias": "Chinese version of Instagram",
    "redbook_users": "Women aged 18-34 years (male user ratio: over 30%)",
    "redbook_active_users": "260 million people  ",
    "redbook_active_creator": "20 million viewers",
    "redbook_video_type": "Landscape, Portrate",
    "redbook_view": "None. ",
    "redbook_specialty_1": "*The trend of online marketing in China",
    "redbook_specialty_2": "*Social network (SNS) + e-commerce platform that is hot in China these days",
    "redbook_specialty_3": "*Configured with a go-board layout",
    "redbook_specialty_4": "*Many users have the genes of the so-called Wang Hong, who is young and knowledgeable",
    "redbook_specialty_5": "*Reviews and reviews of the actual purchase of the product are an important part of the content",
    "redbook_specialty_6": "* Product usage reviews and travel destinations can be written in text",
    "redbook_specialty_7": "*Combines video and live broadcasting functions",
    "redbook_specialty_8": "*Review content: Appeal to potential consumers of the product and even get viral effects",
    "qqworld": "QQ World",
    "qqworld_alias": "Chinese version of Whatapp",
    "qqworld_users": "Many people have been born since 1995",
    "qqworld_active_users": "350 million people",
    "qqworld_active_creator": "Over 1.2 million viewers",
    "qqworld_video_type": "Landscape, Portrate",
    "qqworld_view": "None.",
    "qqworld_specialty_1": "*Shortform video platform in the form of a combination of Chinese Whatsapp + sns",
    "qqworld_specialty_2": "*In addition to uploading videos, photos and text can also be uploaded",
    "qqworld_specialty_3": "*Users: Many young people, including students",
    "platform_tip": "We are currently pioneering platforms to meet more subscribers. Please look forward to it.",
    "feature": "Feature guide",
    "feature_upload_1": "Uploading allows you to upload your original platform content, thumbnails, and titles/descriptions in the original language. adoba.AI will adjust the titles and descriptions to the local language and automatically generate thumbnails suitable for each platform, providing optimized content for uploading.",
    "feature_upload_2": "With adobaro, you can simultaneously distribute to multiple platforms with a single upload.",
    "feature_upload_3": "The number of uploads available depends on each plan.",
    "upload_table_free": "Free",
    "upload_table_basic": "Basic",
    "upload_table_pro": "Pro",
    "upload_table_limit": "Number of uploads",
    "upload_table_no_pass": "Pass not certified",
    "upload_table_pass": "Pass authentication",
    "upload_table_limit_1": "Once a week",
    "upload_table_limit_2": "Twice a week",
    "upload_table_limit_3": "Once a day per platform",
    "upload_table_limit_4": "Unlimited",
    "upload_table_limit_5": "(Up to 5 times a day per platform)",
    "feature_upload_4": "Free plan's weekly upload count is initialized every Monday at midnight (GMT+09:00).",
    "feature_upload_5": "Why is there a limit to the number of uploads?",
    "feature_upload_6": "Different platforms have different policies, but by default, if there is a lot of traffic from accounts that have just been created, the accounts may be blocked or there may be sanctions. Please consider it as a means to secure your account against platform sanctions.",
    "feature_upload_7": "Then why is there the expression \"unlimited\" in Proplan?",
    "feature_upload_8": "We are discussing with each platform company to ensure the safety of the contents uploaded through Adobaro. As the discussion progresses, the number of upload restrictions may increase or be lifted, and any changes that will be made in the future will be applied only to the plan and updated.",
    "feature_upload_9": "Why is a verification code required to upload content?",
    "feature_upload_10": "This is a necessary security process for ID verification that appears irregularly during linking adobaro with Chinese platforms. Although it might be unconfortable in the beginning, please verify through your mobile phone number for information security.",
    "feature_upload_11": "The uploaded content will auto save in your File Storage.",
    "feature_upload_12": "  The uploaded content is auto saved directly in File Storage - My documents regardless of content review for convenient use of adobaro.",
    "feature_material_1": "Easily manage your uploaded content through File Storage",
    "feature_material_2": "Content registered in File Storage can be uploaded to other platforms without additional work",
    "feature_material_3": "Free storage capacity comes with an initial 5GB, depending on the plan you choose.",
    "material_table_free": "Free",
    "material_table_basic": "Basic",
    "material_table_pro": "Pro",
    "material_table_storage": "Storage Capacity",
    "feature_material_4": "We are preparing for additional capacity expansion through points when capacity is exceeded.",
    "feature_content_1": "This features allows you to manage content uploaded on each platform and further distribute to other platforms.",
    "feature_content_2": "Only content that has passed the content review can be viewed in content.",
    "feature_content_3": "My content does not show up?",
    "feature_content_4": "adoba AI will conduct a content review before uploading to the platform. If the content is not visible, it can be regarded as not uploaded because it did not pass the content review. For more information, please refer to the content review result email.",
    "feature_content_5": "The uploaded content will auto save in your File Storage.",
    "feature_content_6": "The uploaded content is auto saved directly in File Storage - My documents regardless of content review for convenient use of adobaro.",
    "global_pass": "Global PASS verification",
    "feature_pass_1": "Global PASS is a personal certificate that allows you to secure channel security and initiate revenue activities on each platform through Adobe. The platform can be opened only after Global PASS certification, and revenue from the platform is generated.",
    "feature_pass_2": "For the Global PASS, the following documents are required for ID verification:",
    "feature_pass_2_1": "Passport image",
    "feature_pass_2_2": "Selfie with your passport image",
    "feature_pass_2_3": "adobaro General Power of Attorney",
    "feature_pass_3": "Why is a passport required?",
    "feature_pass_4": "Just as you need a passport when traveling abroad, a passport is also required for ID verification when operating on overseas platforms. Passport information is used solely for platform-related operations, and the usage history can be checked in the top right corner under Email - PASS Center - Pass usage record.",
    "feature_pass_5": "Why is a verification code also required?",
    "feature_pass_6": "Since a passport contains sensitive information, it is necessary for security reasons to verify your identity. Although it may be slightly inconvenient at first, we ask for verification through a mobile phone number for information security.",
    "feature_pass_7": "After obtaining the Global PASS verification, the following benefits are added:",
    "feature_pass_7_1": "Platform revenue maximization",
    "feature_pass_7_2": "Upgrade to two free uploads per week",
    "feature_pass_7_3": "Data statistics provision",
    "feature_pass_7_4": "More platforms available for expansion",
    "feature_pass_7_5": "Larger file storage capacity",
    "feature_pass_7_6": "We are currently preparing more benefits.",
    "feature_pass_8": "Why is my channel affiliated with adoba MCN after the PASS verification?",
    "feature_pass_9": "Revenues generated and accumulated from individual channels in Chinese platforms cannot be settled abroad. However, if the channel is affiliated with adoba MCN, overseas settlement is possible through official partnerships between each platform. Therefore, if you verify your PASS, you automatically become a member of adoba MCN. At the same time, you can receive the benefits of maximizing platform revenue.",
    "feature_pass_10": "After completing the Global PASS verification, a verification card is generated, and the validity period of the verification is one year from the application date.",
    "feature_pass_11": "If the validity period expires, the benefits of the Global PASS will no longer be provided. However, don't worry, we will send you a notification before the expiration of the verification period.",
    "customer_service": "Customer service",
    "customer_service_1": "Our customer service is currently operated {0} .",
    "customer_service_1_1": "Help Desk",
    "customer_service_2": "We are currently upgrading our customers, so please understand if you are going through any inconvenience."
  },
  "auth": {
    "modal_title": "Welcome to adobaro",
    "modal_subtitle": "Global Pass, the easiest and safest way to protect your channel!👇\nIf you complete your Global Pass verification, we will issue you a Global Pass verification card along with benefits like channel ownership verification, monetization, copyright protection, etc.🎉",
    "modal_subtitle_tips": "You can experience adobaro before the Global PASS verification through the trial version.",
    "modal_plus_title": "Global PASS Plus required",
    "modal_plus_subtitle": "You can access the following after completing the Global Plus PASS verification.",
    "modal_item1": "The start of revenue generation from platform activities!",
    "modal_item2": "More platform openings",
    "modal_item3": "Customized data analysis sytem",
    "modal_item4": "and many more features.",
    "modal_btn_next": "Next time",
    "modal_btn_go": "Start verification",
    "modal_btn_trial": "Start trial version",
    "modal_certify_title": "Have you heard of platform monetization requirements?",
    "modal_certify_column_1": "Requirements",
    "modal_certify_column_2": "Platform Y",
    "modal_certify_column_3": "adobaro",
    "modal_certify_require_1": "Subscribers",
    "modal_certify_require_2": "Watch hours",
    "modal_certify_require_3": "Views",
    "modal_certify_company_1": "1,000",
    "modal_certify_company_2": "4,000 hours",
    "modal_certify_company_3": "10 million",
    "modal_certify_ro_1": "0",
    "modal_certify_ro_2": "0",
    "modal_certify_ro_3": "0",
    "modal_certify_explication_1": "Platform Y takes approximately 7 months until monetization.\nadobaro will monetize immediately once Global PASS verification is complete.",
    "modal_certify_explication_2": "THAT'S NOT ALL!\nOpenable platform expansion\nUpgrade to 2 free uploads per week\nIncreased file storage capacity and so much more!",
    "modal_certify_explication_3": "Get Global PASS verification benefits NOW",
    "modal_certify_btn": "Start monetization after verifying your PASS!",
    "auth_center": "Global PASS",
    "auth_center_subtitle": "PASS management",
    "auth_global": "Global PASS",
    "auth_creator": "Global Plus PASS",
    "auth_history": "Usage History",
    "auth_global_subtitle": "Platform monetization, channel safety improvement",
    "auth_creator_subtitle": "Can be opened after fulfilling conditions",
    "auth_history_subtitle": "PASS Usage History",
    "auth_global_title": "Global PASS",
    "auth_creator_title": "Global Plus PASS",
    "auth_history_title": "PASS History",
    "creator_auth_tip": "Please proceed with the Global PASS first.",
    "pass_title": "Verified",
    "passport_title": "PASSport information",
    "passport_tip": "You must verify the Global PASS to receive payment by platform.\nPlease submit the documents required for verification as follows.",
    "platform_title": "Platform information",
    "platform_name": "Platform name",
    "platform_url": "Platform URL",
    "passport_dashboard_title": "Scanned Image of Signed Passport",
    "passport_dashboard_tip": "Please ensure the document is signed before uploading.",
    "passport_handhold_title": "Selfie with Your Signed Passport",
    "passport_handhold_tip": "Your passport must be clearly photographed with the signature visible.",
    "platform_dashboard_title": "Screenshot of studio dashboard",
    "platform_handhold_title": "A selfie with your studio dashboard",
    "btn_submit_picture": "I agree to provide information for verification.",
    "protocol_tip": "I have fully read and agree to the {0}.",
    "protocol_platform": "adobaro General Power of Attorney",
    "passport_question": "Why is my passport required?",
    "passport_answer": "Just as you carry a passport when traveling abroad, a passport is also used for ID verification when operating foreign platforms.\nID verification is necessary in order to register on Chinese platforms. Your personal information is protected through adobaro's Global PASS verification system.\nWith the Global PASS verification, you are granted upload permissions on various platforms without separate evaluations, and you can also generate revenue from activities on these platforms.",
    "passport_income_comparison": "Comparison of Y Company's monetization conditions with adobaro",
    "passport_pass_success": "Meet all monetization conditions by verifying the adobaro Global PASS",
    "pass_no": "Verification code",
    "pass_duration": "Verification period",
    "pass_explain": "The passport information submitted for {0} is not displayed for the protection of personal information.",
    "pass_in_review": "{0} has been applied.\nPlease wait for the review.\nThe review will take about 3~5 business days, and during the review period, an email for identity verification will be sent to you.",
    "pass_in_fail": "{0} has failed the review.\nReason: {1}",
    "pass_expire_warning": "{0} and {1} are set to expire.\nPlease apply again to use the service.",
    "pass_expire_already": "{0} has been expired.\nPlease apply again to use the service.",
    "pass_submit_success_tip": "{0} has been safely submitted.\nThe review will take about 3~5 business days, and you can apply for platform opening while waiting for the review.",
    "pass_submit_fail_tip": "Failed to submit {0}",
    "auth_attention": "Cautions for verification",
    "global_attention_question_1": "1. Why is my passport required?",
    "global_attention_answer_1": "Answer",
    "global_attention_question_2": "Question 2",
    "global_attention_answer_2": "Answer",
    "global_attention_question_3": "Question 3",
    "global_attention_answer_3": "Answer",
    "global_attention_question_4": "Question 4",
    "global_attention_answer_4": "Answer",
    "global_attention_pass_question_1": "1. How do I cancel my Global PASS after verification?",
    "global_attention_pass_answer_1": "Answer",
    "global_attention_pass_question_2": "Question 2",
    "global_attention_pass_answer_2": "Answer",
    "creator_attention_question_1": "1. Why is the platform dashboard required?",
    "creator_attention_answer_1": "Answer",
    "creator_attention_question_2": "Question 2",
    "creator_attention_answer_2": "Answer",
    "creator_attention_question_3": "Question 3",
    "creator_attention_answer_3": "Answer",
    "creator_attention_question_4": "Question 4",
    "creator_attention_answer_4": "Answer",
    "creator_attention_pass_question_1": "1. After verification, how do I cancel my Global Plus PASS verification?",
    "creator_attention_pass_answer_1": "Answer",
    "creator_attention_pass_question_2": "Question 2",
    "creator_attention_pass_answer_2": "Answer",
    "attention_know_more": "Learn more",
    "cancel_auth": "Cancel PASS verification",
    "cancel_auth_title": "Are you sure you want to cancel PASS verification?",
    "cancel_auth_subtitle": "If the PASS verification is canceled, revenue from all operating channels will be suspended, and all opened channels will be withrawn. Would you like to proceed?",
    "cancel_auth_success": "Successfully canceled PASS verification",
    "cancel_auth_fail": "Failed to cancel PASS verification",
    "attention_approve": "Satisfied with the answer?",
    "history_country": "Country",
    "history_platform": "Platform",
    "history_auth_type": "PASS type",
    "history_use_todo": "Purpose",
    "history_timestamp": "Time",
    "use_type_handling": "Copyright takedown",
    "use_type_open_income": "Platform revenue",
    "use_type_open_platform": "Channel opening",
    "upload_global_pass": "Global Pass Verification",
    "upload_cancel": "Cancel",
    "upload_confirm": "Confirm",
    "global_pass_failed": "Passport Verification Failed",
    "global_pass_failed_des": "According to platform feedback, the certification has failed. The specific reason is: {0}. Please review the information and re-upload it.",
    "upload_btn": "Upload information",
    "account_create_success": "Consent to Global Pass Usage",
    "allow_global_pass": "Please agree to the Global Pass Usage for platform channel opening and verification processes.",
    "allow_and_accredit": "I Agree",
    "upload_global_pass_subtitle": "Please submit your passport for Global Pass verification.",
    "back_trial_version": "Close",
    "upload_pass_more": "Your passport information has already been submitted.",
    "upload_pass_tips": "*Submitted data will be used only for Global Pass verification purposes, and all personal information will be strictly protected."
  },
  "home": {
    "read_more": "Read more",
    "all_platform_statistic": "My channels status",
    "statistic_date": "Last updated on {0}",
    "statistic_platform": "Number of Channels",
    "all_platform_fans": "Subscribers(Total)",
    "all_platform_plays": "Views(Total)",
    "latest_content": "Updated",
    "latest_popular": "My channels",
    "read_details": "Details",
    "earnings_overview_title": "Revenue Overview",
    "earnings_money": "Settleable Amount",
    "earnings_point": "Point",
    "platform_news": "Platform news",
    "news_content": "Welcome to adobaro!\nIn platform news, we introduce stories and new features of adobaro. Please look forward to updates.",
    "news_help_btn": "Explore help center",
    "unlocked_task_card": "Check my task card",
    "unlocked_to_content": "Content management",
    "unlocked_to_upload": "Upload content",
    "unlocked_to_open": "Platform opening"
  },
  "upload": {
    "file_btn_status_removed": "Upload",
    "file_btn_status_uploading": "Uploading",
    "file_btn_status_done": "Change file",
    "file_btn_status_error": "Reupload",
    "file_dragger_subtitle": "Upload or drag and drop file here",
    "file_dragger_tip": "Supported file formats : {0}",
    "file_status_uploading": "Successfully uploaded",
    "file_status_done": "Complete",
    "file_status_error": "Failed to upload",
    "file_type_error": "The file format is not supported.",
    "file_limit_size": "File size must not exceed {0}",
    "file_limit_size_tip": "(Maximum {0})",
    "file_limit_min_size": "(*under {0})",
    "file_change_tip": "Change file",
    "file_upload_image_tip": "Upload thumbnail",
    "file_change_image_tip": "Change thumbnail",
    "file_name_already_exist": "The file name already exists in the file storage. Please change the file name and upload again",
    "file_size_exceeds_capacity": "Storage is full.\nPlease check the remaining capacity.",
    "example_pic_tip": "Sample",
    "guide_tips": "All you need is {0}",
    "guide_tip_content": "original video, thumbnail, title, and description",
    "guide_subtitle": "(✿◡‿◡) When you upload your content, a title and thumbnail optimized for each platform will be automatically generated and uploaded. ✌️",
    "radio_quick": "快速上传视频（Youtube链接）",
    "radio_local": "本地上传视频",
    "label_video_link": "视频地址",
    "video_link_holder": "请输入youtube视频链接地址",
    "video_youtube_link_invalid_msg": "Youtube链接无效！",
    "video_youtube_title_msg": "无法获取视频标题，请稍后重试！",
    "guide_upload_btn": "Confirm",
    "page_title": "Upload",
    "page_subtitle": "(✿◡‿◡) When you upload your content, a title and thumbnail optimized for each platform will be automatically generated and uploaded. ✌️",
    "upload_file_holder": "Please upload file",
    "upload_file_complete_tip": "Please proceed after file upload is complete.",
    "select_platform": "Select Channels",
    "select_platform_holder": "Please select channels to upload",
    "open_more_platform": "Open new channels",
    "basic_info": "Content information",
    "basic_info_tip": "adoba.AI automatically converts and uploads content optimized for each platform.",
    "basic_content_title": "Title",
    "basic_content_title_holder": "Please enter the content title. This will be translated into Chinese in a format optimized for the each platform and uploaded accordingly.",
    "basic_content_cover": "Thumbnail",
    "basic_content_cover_holder": "Please upload thumbnail",
    "basic_content_cover_upload": "Supported file formats : {0}",
    "basic_content_cover_upload_tip": "⭐ The AI Thumbnail feature automatically creates and uploads thumbnails optimized for each platform.\r\n🚨 To use the AI Thumbnail feature, please make sure to upload a clean version of the thumbnail image without any text.",
    "basic_content_cover_change_tip": "Change thumbnail",
    "basic_content_intro": "Description",
    "basic_content_intro_holder": "Please enter the content description. This will be translated into Chinese in a format optimized for the each platform and uploaded accordingly.",
    "basic_content_subtitle": "Select subtitles",
    "basic_content_subtitle_holder": "Please select subtitles",
    "basic_content_timer_release": "Schedule upload",
    "basic_content_timer_release_tip": "You can only select time after {0} hours",
    "btn_release": "AI Smart Upload",
    "btn_draft": "Save draft",
    "draft_title": "Saved drafts",
    "draft_subtitle": "There is a draft that hasn't been uploaded yet.\nWould you like to continue?",
    "draft_read_image_error": "Failed to load image. Please try again.",
    "draft_save_tips": "Draft can be saved after upload is completed.",
    "draft_save_success": "Draft saved",
    "release_content_success": "Your upload request has been completed. 🎉\nWe will notify you of the upload progress results through notifications and email.\n💡 Please note that content is reviewed for compliance with each platform's regulations, including checks for illegal and adult material. Your upload request may be rejected based on these reviews.",
    "release_content_fail": "Failed to upload",
    "release_count": "{0} uploads available",
    "platform_frozen": "Locked",
    "platform_unbound": "Unverified",
    "trial_count_out": "You cannot upload as this week's trial version upload has been used.",
    "publish_count_out": "You cannot upload as you have used all your available uploads.",
    "release_upload_tips": "⭐AI Smart Upload feature analyzes content and key activation times for each platform and automatically uploads on optimal times accordingly.\n🚨ID verification through verification codes may be required.",
    "release_time_tips": "⚠️The number of uploads will be updated at {0} local time (UTC 00:00)."
  },
  "material": {
    "page_title": "File Storage",
    "page_subtitle": "Easily manage uploaded content and distribute to other platforms all at once",
    "sider_my_file": "My documents",
    "sider_enlarge_capacity": "Storage",
    "menu_video": "Video",
    "menu_images": "Image",
    "menu_sounds": "Sound effect",
    "menu_subtitles": "Subtitles",
    "menu_fonts": "Font",
    "menu_bgm": "BGM",
    "menu_backup_space": "Backup cloud",
    "alert_capacity": "Expand your storage space to make full use of adobaro features",
    "alert_upgrade": "Expand storage space",
    "all_backups": "Backup all",
    "all_delete": "Delete all",
    "all_download": "Download all",
    "confirm_title_delete": "Are you sure you want to delete?",
    "confirm_ok": "Delete",
    "confirm_cancel": "Cancel",
    "selected_items": "{0} files selected",
    "backup_btn": "Backup",
    "delete_btn": "Delete",
    "download_btn": "Download",
    "pagination_total": "{0} files",
    "video_title": "All videos",
    "video_delete_confirm": "Are you sure you want to delete {0} videos?",
    "video_deleted_description": "Secondary distribution of deleted videos on new platforms is not possible",
    "video_name": "File name",
    "video_size": "Size",
    "video_published_platform": "Distribution platform",
    "video_opened_platform": "Opened platform",
    "image_title": "All images",
    "subtitles_title": "All subtitles",
    "subtitles_delete_confirm": "Are you sure you want to delete {0} documents?",
    "subtitles_deleted_description": "Secondary distribution of deleted videos on new platforms is not possible",
    "subtitles_name": "File name",
    "subtitles_size": "Size",
    "subtitles_lang": "Language",
    "modal_title": "Change name",
    "modal_name_label": "Name",
    "modal_name_holder": "Enter name",
    "modal_reference_batch": "Content that is being reviewed for upload cannot be deleted",
    "modal_reference_single": "This content is being reviewed for uploading. It cannot be deleted"
  },
  "content": {
    "page_title": "Content Management",
    "page_subtitle": "Easily manage your content across multiple channels  with adobaro. Gain swift access to each channel's upload progress and data.✌️",
    "radio_all": "All",
    "radio_play": "Sort by views",
    "radio_like": "Sort by likes",
    "page_details_title": "View content details",
    "details_under_review": "AI content inspection is in progress, please wait a moment",
    "details_in_failure": "AI inspection has been completed.\nPlease modify the content according to the modifications below and upload it again.",
    "base_info": "Basic info",
    "base_video": "Video",
    "base_cover": "Thumbnail",
    "base_title": "Title",
    "base_subtitle": "Subtitles",
    "base_upload_date": "Uploaded on",
    "empty_cover": "Thumbnail is missing",
    "mcn_upload": "MCN upload",
    "distributed_platform": "Upload Status",
    "distributed_name": "Platform",
    "distributed_state": "Status",
    "distributed_subtitle": "Subtitles",
    "distributed_plays": "Views",
    "distributed_likes": "Likes",
    "distributed_upload_date": "Uploaded on",
    "distributed_action": "Operation",
    "status_playing": "Upload Success",
    "status_opening": "Under Review",
    "status_failure": "Failed to upload",
    "status_unpublished": "Ready to Upload",
    "status_not_opened": "No Channel Available",
    "status_frozen": "Locked",
    "status_deleting": "Deleteing",
    "status_deleted": "Deleted",
    "action_operate": "Link",
    "action_ask": "Ask for help",
    "action_publish": "Upload",
    "action_open": "Channel opening",
    "action_check": "View",
    "action_analysis": "Analysis",
    "action_edit": "Edit",
    "action_delete": "Delete",
    "action_unfreeze": "Unlock",
    "delete_video_title": "Videos that have been requested for deletion cannot be restored. Are you sure you want to delete?",
    "delete_video_success": "Your request for deletion has been received",
    "delete_video_error": "Your deletion request has failed",
    "republish_platform_success": "Successfully uploaded",
    "republish_platform_error": "Failed to upload",
    "republish_platform_error_insufficient": "Failed to upload video. Exceeded number of possible uploads.",
    "republish_content_can": "Would you like to upload this content to your {0} channel?\r\nRemaining uploads: {1}",
    "republish_content_cannot": "Video cannot be uploaded because your remaining uploads to {0} platform is {1} videos.",
    "republish_content_certify_warning": "Upload will be available after completing the authentication. Please proceed with the authentication first.",
    "video_url_error": "Upload URL not found",
    "failure_reason": "Reason for failure",
    "distributed_state_intro": "Upload Success : The content review and upload process have been completed.\nUnder Review : The content is under review, with essential processes for platform uploading being implemented.\nReady to Upload : A channel has been opened, and the content is ready for an upload request.\nNo Channel Available : You need to open a channel to upload your content.",
    "no_data": "The original file has been deleted from file storage."
  },
  "translation": {
    "page_subtitle": "You can translate subtitles here.",
    "srt_translation": "SRT Conversion",
    "ai_translation": "AI Translation",
    "manual_translation": "Professional Translation",
    "translation_download": "Request Details",
    "language_select": "Please select a language:",
    "language_zh": "Chinese (Simplified)",
    "language_ko": "Korean",
    "language_en": "English",
    "srt_translation_subtitle": "Do you have an SRT file? We can convert it to Chinese (Simplified) for you.",
    "srt_step_language": "Please select the target language",
    "srt_step_language_tip": "The uploaded file is expected to be translated into {0}.",
    "srt_step_lang_model": "Choose a language translation model.",
    "srt_lang_model_holder": "Please select a language translation model.",
    "srt_lang_model_tip_tiny": "Very fast but okay accuracy",
    "srt_lang_model_tip_base": "Fast with decent accuracy",
    "srt_lang_model_tip_small": "Normal speed with good accuracy",
    "srt_lang_model_tip_medium": "Slow but great accuracy",
    "srt_lang_model_tip_large": "Very slow but amazing accuracy",
    "srt_select_translation_engine": "Please select a translation engine",
    "srt_select_chatgpt_model": "Please select a ChatGPT model",
    "srt_step_upload_file": "Please upload your SRT file.",
    "srt_step_tip_manual": "Do you need a perfect level of translation? We can assist with that in {0}.",
    "srt_translation_tip1": "This service is provided for free.",
    "srt_translation_tip2": "This is usually completed within 1 hour. Please check your request details.",
    "srt_translation_tip3": "This works based on a translation engine, so the accuracy of the translation might not be perfect.",
    "ai_translation_subtitle": "Do you only have a video without an SRT file? We can create a Chinese (Simplified) SRT file just with the video.",
    "ai_step_title_1": "Please select the language of the video.",
    "ai_step_tip_1": "The selected video language will be translated into {0}.",
    "ai_step_title_2": "Upload Video",
    "ai_file_type": "Upload Format",
    "ai_file_type_video": "Uplaod directly",
    "ai_file_type_youtube": "YouTube URL",
    "youtube_url": "Please enter the YouTube URL.",
    "youtube_url_placeholder": "Example: https://www.youtube.com/adobaro",
    "youtube_url_error": "Incorrect YouTube URL format",
    "ai_step_tip_2": "Do you need a perfect level of translation? We can assist with that in {0}.",
    "ai_translation_tip1": "This service is provided for free.",
    "ai_translation_tip2": "This is usually completed within 24 hours. Please check your request details.",
    "ai_translation_tip3": "The accuracy of the translation may be imperfect due to it working based on the SRT conversion engine and the translation engine.",
    "manual_translation_subtitle": "Do you want better quality translations? Request a professional translation.",
    "manual_translation_tip1": "This is a paid service.\n5,000 KRW per minute for translation from Korean or English to Chinese(Simplified)\n2,000 KRW added when selecting 'Video Description' tranlsation\n2,000 KRW per minute when 'Subtitles Request Only' is selected",
    "manual_translation_tip2": "This is usually delivered in SRT format within 48 hours. Please check your request details.",
    "manual_translation_tip3": "Before uploading, please make sure that you are the owner of the copyright to use/redistibute the video.\nIf consent from the copyright holder is required, request tranlsation after otaining appropriate consent.",
    "manual_translation_tip4": "Please make sure that the video does not violate copyright laws or constitute defamation.\nPlease make sure that it does not contain sexually explicit, abusive, or illegal content.",
    "video_duration": "Video Length",
    "video_title": "Video Title",
    "video_title_tip": "Please enter the video title.",
    "video_intro": "Video Description",
    "video_intro_tip": "Please enter the video description.",
    "video_intro_check": "Select if you wish to also translate the video description.",
    "subtitle_file": "Subtitle file",
    "subtitle_file_tip": "If you already have a subtitle file, you can receive a discount.",
    "manual_type": "Subtitles only/without translation",
    "manual_type_tip": "Select if you only want an SRT file in the video's language without translation.",
    "manual_language_tip": "The translation will be from {0} to {1}.",
    "manual_unit_count": "{0} items",
    "manual_fee": "Total request amount",
    "manual_fee_unit": "Won(KRW)",
    "protocol_tip": "I have understood and agree with the terms of service.",
    "protocol_check_tip": "You must agree to the terms of service first.",
    "btn_manual_apply": "Request professional translation.",
    "payment_process": "The payment process will begin.",
    "translation_download_subtitle": "You can check the translation you requested here.",
    "file_name": "File Name",
    "translation_category": "Category",
    "service_category": "Request Type",
    "translation_status": "Progress Status",
    "target_language": "Target Language",
    "apply_date": "Requested On",
    "translation_action": "Requested Subtitles",
    "action_download": "Download",
    "action_fail_reason": "Reasons for failure",
    "select_apply_date": "Please select the request date.",
    "start_date": "Start Date",
    "end_date": "End Date",
    "option_all": "All",
    "service_cate_translate": "Translation request",
    "service_cate_srt": "Request Subtitles Only",
    "translation_status_waiting": "Pending",
    "translation_status_translating": "In Progress",
    "translation_status_success": "Completed",
    "translation_status_fail": "Failed",
    "total_count": "Total of {0} data items",
    "apply_success": "Request Successful",
    "apply_fail": "Request Failed",
    "btn_continue_translate": "Continue Request",
    "btn_retry_translate": "Re-request",
    "btn_translate": "Translation Request",
    "success_tip": "You can check the progress status of the current request {0}, and subtitles can be downloaded once the translation is complete.",
    "success_tip_list": "Request list",
    "fail_tip": "The request has failed; please contact {0} and we will resolve it for you.",
    "fail_tip_customer": "Customer center",
    "fail_already_exist": "Duplicate request already exists."
  },
  "platform": {
    "guide_title": "Channel Analysis",
    "guide_subtitle": "If you enter your channel ID, your optimal platform will be recommended through AI analysis.",
    "guide_platform_holder": "Select platform",
    "guide_platform_url_holder": "Enter your channel ID",
    "guide_platform_url_error": "Confirm your channel ID",
    "guide_platform_already": "The platform channel has already been registered.",
    "guide_platform_confirm": "Once you submit your channel information, it cannot be edited.\nPlease press 'Confirm' if the information is correct.",
    "guide_platform_tip": "Where can I check my Channel ID?",
    "guide_btn_recommend": "Platform recommendation",
    "guide_platform_error": "Failed to recommend platform",
    "guide_skip_title": "Later",
    "guide_skip_subtitle": "Please tell us why you would like to do it later",
    "guide_skip_holder": "Select reason",
    "guide_skip_reason_1": "I am not operating a channel",
    "guide_skip_reason_2": "I already have a channel opened",
    "guide_skip_reason_3": "The platform I want is unavailable",
    "guide_skip_reason_4": "Other",
    "guide_skip_btn": "Go to home",
    "guide_analysis_title": "Analyzing",
    "guide_analysis_subtitle": "The best platform for your channel will be recommended based on the analysis.",
    "guide_analysis_fail_title": "There are too many current users.",
    "guide_analysis_fail_subtitle": "We will notify you via e-mail once the analysis is complete.",
    "guide_analysis_fail_btn_next": "Later",
    "guide_analysis_fail_btn_confirm": "Tell me more",
    "guide_analysis_done_title": "Channel Analysis Complete",
    "guide_analysis_done_subtitle": "The content you upload will automatically be optimized to the selected platform.",
    "guide_analysis_platform_about": "Learn more",
    "guide_analysis_btn_open": "Apply for Opening",
    "guide_analysis_btn_already": "Link Existing Channel",
    "guide_analysis_btn_next": "Maybe next time",
    "guide_recommend_fail": "Failed to open platform",
    "guide_bind_title": "Link channel",
    "guide_bind_subtitle": "If you have a channel that has already been opened, try linking it to adobaro.\nYou can manage all platforms at once.",
    "guide_bind_tip": "Why do I need to enter my password?",
    "guide_bind_btn_confirm": "Link channel",
    "guide_bind_btn_next": "Link next time",
    "guide_bind_success": "Platform opening application submitted.\nWe will soon notify you via e-mail.",
    "guide_open_phone_title": "Register Phone Number",
    "guide_open_phone_subtitle": "Please enter your mobile phone number.",
    "guide_enter_phone": "Please enter your phone number",
    "guide_open_btn_verify": "Next",
    "guide_open_send_sms_error": "Failed to send verification code",
    "guide_open_verify_title": "Send Verification Code",
    "guide_open_verify_subtitle": "A verification code will be sent once you click the Send Verification Code button.There could be delays depending on the system status, so please wait for a while.SMS Example:",
    "guide_open_btn_verifying": "Verify",
    "guide_open_verify_error": "Failed to verify",
    "manage_title": "Channel Management",
    "manage_subtitle": "(✿◡‿◡)Easy and safe channel opening, available now at adobaro.\nAll complex processes required for channel opening and verification are carried out accurately and safely at adobaro.✌️' ֊'",
    "applied_platform": "Channel Opening Status",
    "btn_refresh": "Refresh",
    "btn_refresh_tip": "Click to update channel opening status",
    "pending_tip_content": "The platform will be opened with the mobile phone number you entered. Once the 'Send verification code' button is generated after refreshing the page, click the button and proceed with verification.",
    "btn_all": "All",
    "btn_play_most": "Most viewed",
    "btn_like_most": "Most liked",
    "platform_empty": "Start channel opening",
    "platform_frozen_tip": "Click to view or unlock",
    "platform_view_btn": "View Platform",
    "status_done": "Opening/Verification Complete",
    "status_opening": "Opening in progress",
    "status_verifying": "Verification in progress. Please wait.",
    "status_verify_failed": "Verification failed",
    "status_failed": "Opening failed",
    "status_unbind": "Opening Complete, Verification in Progress",
    "status_binding": "Approved opening, verification in progress",
    "status_bound_fail": "Verification Error",
    "btn_status_done": "Upload",
    "btn_status_failed": "View details",
    "status_opening_intro": "Opening in Progress : You are in the process of creating a channel.",
    "status_unbind_intro_1": "Opening Complete, Verification in Progress : The channel opening process is complete and the required verification process is underway according to each platform's rules, including identity verification, ownership verification, channel verification, and conformance check. \n",
    "status_unbind_intro_2": "The first upload is required to complete the verification process.",
    "status_unbind_intro_3": "*Depending on the platform, this process can take 5-days.",
    "status_done_intro": "Opening/Verification Complete : The channel opening and process is complete.",
    "un_apply_platform": "Available Platforms",
    "open_count": "{0} users have already opened",
    "lastest_open": "{0} {1} users opened",
    "btn_open": "Open Channel",
    "btn_reapply": "Apply again",
    "open_platform_title": "Channel Opening",
    "btn_contact_us": "Contact Us",
    "platform_recommend": "Recommended",
    "platform_disable": "Under Maintenance",
    "platform_disable_title": "Under Maintenance",
    "platform_disable_content": "{0} channel opening is currently under maintenance due to the platform's system check. Please contact our customer service for questions.🧏‍♀️",
    "platform_conditions_required": "🚨 Application Unsuccessful: You have not met the conditions required for channel opening. Please review the application conditions and try again.",
    "modal_bound_fail_title": "Verification Failure 👀",
    "modal_bound_fail_content": "Please check the information below\n\n⚠️ Reason: {0}",
    "modal_bound_fail_btn": "Complete Verification",
    "open_platform_subtitle": "All complex processes required for channel opening and verification are carried out accurately and safely at adobaro.",
    "details_title": "Platform summary",
    "details_info_title": "Basic information",
    "details_info_avatar": "Profile picture",
    "details_info_intro": "Channel description",
    "details_info_intro_empty": "There is no channel description",
    "details_info_intro_holder": "Please enter channel description",
    "details_info_date": "Opened on",
    "details_info_date_empty": "There is no data",
    "details_info_date_holder": "Please select the channel subscription date,",
    "details_info_classify": "Channel category",
    "details_info_classify_empty": "There is no data",
    "details_info_classify_holder": "Please select your category",
    "details_info_income": "Platform revenue",
    "details_info_order": "Advertisements",
    "details_info_btn_again": "New channel name",
    "details_info_btn_open": "Open now",
    "details_info_btn_edit": "Edit basic information",
    "details_info_btn_submit": "Save and edit",
    "details_data_overview_title": "Data summary",
    "details_data_radio_yesterday": "Yesterday",
    "details_data_radio_nearly7": "Last 7 days",
    "details_data_radio_nearly30": "Last 30 days",
    "details_data_fans": "Total subscribers",
    "details_data_likes": "Total likes",
    "details_data_platform": "Total platform revenue",
    "details_data_published": "Total distributions",
    "details_data_plays": "Total views",
    "details_data_ad": "Total advertising revenue",
    "details_release_video_title": "Distributed content",
    "trial_modal_title": "adobaro trial version",
    "trial_modal_subtitle": "Please read carefully before using adobaro trial version!",
    "trial_modal_btn_agree": "Start",
    "trial_protocol_title": "By pressing start, you fully understand and agree to the above information.\nHave a pleasant experience.",
    "trial_msg_toggle_error": "Failed to enter the Experience edition.",
    "trial_msg_certified_error": "The trial version cannot be accessed because you have already verified your Global Pass.",
    "trial_protocol_item_1": "All trial version users' content are shared, so please be careful about the content you upload.",
    "trial_protocol_item_2": "All content uploaded on the trial version will be reset the next day.",
    "trial_protocol_item_3": "The data of content uploaded on the trial version is the actual data of the channel created for the trial version.",
    "trial_protocol_item_4": "All features of the trial version are the same as those of the actual version.",
    "trial_protocol_item_5": "Some features are not supported to ensure a smooth experience.",
    "trial_protocol_subtitle_2": "By pressing start, you fully understand and agree to the above information.\nHave a pleasant experience.",
    "guide_exit_opening_platform": "Exiting the current process will result in the loss of entered information. The application process will start from the first step next time you open it.",
    "guide_exit_cancel": "Cancel",
    "guide_exit_confirm": "Confirm",
    "guide_submit_success": "Submission successful",
    "guide_waiting_phone_code": "Registration will be completed in 3-5 minutes. Please wait patiently.",
    "guide_know_btn": "I understand",
    "guide_change_account": "Change Channel",
    "guide_phone_code_verify": "Code verification",
    "guide_pass_verify": "Passport verification",
    "guide_pass_code_verify": "Code re-verification",
    "guide_verify_fail": "Verification failed",
    "guide_click_verify": "Click to verify",
    "guide_check_fail_reason": "View the reason for failure",
    "pending_tips": "The platform is currently being activated. Click the refresh button to immediately get the latest information.",
    "doing_warning": "Please try again after 5~10s.",
    "guide_open_submit_success": "You have submitted your phone number.",
    "guide_open_verify_success": "You have submitted your verification code.",
    "guide_click_send": "Send Code",
    "guide_waiting_pass_code": "Platform activation will be completed within 1-2 business days. Please wait patiently.",
    "guide_submit_phone_failed": "Sorry, the system is busy, please try again later.",
    "have_opened": "Opened",
    "guide_open_security": "This platform requires time to open due to platform's internal review (average 2 weeks). You will receive an email and notification when the next step is ready.",
    "guide_get_code_error": "There is an error in the system. Please try again later.",
    "bilibili_tips_1": "bilibili is widely known as 'China's YouTube', and is especially popular among the MZ generation aged 18-35.💖",
    "bilibili_tips_2": "The platform's main userbase is people under the age of 24, making up for 75% of all users.\r\nbilibili also has approximately 300 million monthly active users and 3.8 million active creators.🏆",
    "bilibili_tips_3": "This platform supports both horizontal and vertical video formats, and its unique Danmu(弹幕) feature flying across the screen makes it an attractive digital content for the young generation.",
    "bilibili_tips_4": "- Global Pass Verification",
    "xiaohongshu_tips_1": "Xiaohongshu is widely known as 'China's Instagram', and is popular among women aged 18 to 34.💖 It has 260 million monthly active users and approximately 20 million creators.🏆",
    "xiaohongshu_tips_2": "The platform supports both horizontal and vertical video formats, and is a combination of social networking and e-commerce functions, allowing users to share product reviews, travel destination introductions, etc. in text and video format. Featuring a tiled layout, Xiaohongshu provides attractive content to young users, thereby demonstrating powerful viral marketing effects.",
    "xiaohongshu_tips_3": "- Global Pass Verification",
    "kuaishou_tips_1": "Kuaishou, known as \"China's TikTok,\" is mainly popular among teenagers under the age of 18, and has over 600 million monthly active users. 💖It has more than 2 million active creators and primarily supports vertical videos.🏆",
    "kuaishou_tips_2": "The platform is famous for its lyrical content about daily life and rural life, and its sales through live commerce are high. Recently, the younger user base is increasing, and the platform is emphasizing the importance of content upload cycle.",
    "kuaishou_tips_3": "- Global Pass Verification",
    "iqiyi_tips_1": "iQiyi, known as \"China's Netflix\", is a platform used by users of various ages. 💖The number of monthly active users reaches 540 million and more than 3 million creators are active. It mainly supports horizontal videos.🏆",
    "iqiyi_tips_2": "iQiyi also produces a variety of global content, including dramas and variety shows.\nAmong video service platforms, it has the second largest number of paid subscribers worldwide after Netflix.",
    "iqiyi_tips_3": "- Global Pass Verification",
    "haokan_tips_1": "Haokan Video, known as \"China's Naver\", primarily targets users aged 26-35.💖 The platform has approximately 110 million monthly active users and more than 2 million active creators. It mainly supports horizontal format videos.🏆",
    "haokan_tips_2": "Haokan Video is a representative medium-length video platform of Baidu, China's largest search engine, and has a relatively conservative operating policy due to heavy influence from the government.",
    "haokan_tips_3": "- Global Pass Verification",
    "haokan_tips_4": "- Opened and verified at least 1 Chinese platform",
    "haokan_tips_5": "- Have at least one video featuring your face on YouTube, TikTok, or a Chinese platform channel(*channels with more than 1 million subscribers can go through special reviews without a video featuring your face)",
    "qqworld_tips_1": "QQ World, known as \"China's Instagram\", is a combination of the Chinese version of WhatsApp (QQ) and Instagram, and is especially popular among young users born after 1995. 💖The platform approximately has 350 million monthly active users users and over 1.2 million active creators.\r\nQQ supports videos in both vertical and horizontal formats, but does not provide revenue based on views.🏆",
    "qqworld_tips_2": "There are many young users, mainly students, so modern and diverse content are actively shared.",
    "qqworld_tips_3": "- Global Pass Verification",
    "qqworld_tips_4": "- Opened and verified at least 1 Chinese platform",
    "xigua_tips_1": "Xigua Video, known as \"China's YouTube\", has a similar user ratio of men and women, with 45% of users aged 25-35.💖 The platform has approximately 180 million monthly active users and 3.2 million active creators, and mainly supports horizontal videos.🏆",
    "xigua_tips_2": "The platform has secured a wide user base and diverse content through ByteDance's personalized content recommendation algorithm, which is particularly interested in vlogs and content in the '3 agricultural' fields (agriculture, rural areas, and farmers).\r\nRapid growth is expected for Xigua Video due to  synergy effects through linking with the Douyin platform.",
    "xigua_tips_3": "- Global Pass Verification",
    "xigua_tips_4": "- Opened and verified at least 1 Chinese platform",
    "bilibili_need_time": "Setup Time: Average 1-2 Days",
    "xiaohongshu_need_time": "Setup Time: Average 2-3 Days",
    "kuaishou_need_time": "Setup Time: Average 2-3 Days",
    "iqiyi_need_time": "Setup Time: Average 1-2 Days",
    "haokan_need_time": "Setup Time: Average 10-15 Days",
    "qqworld_need_time": "Setup Time: Average 5-10 Days",
    "xigua_need_time": "Setup Time: Average 5-10 Days",
    "guide_waiting_open_title": "Your channel opening application has been completed.🎊",
    "guide_waiting_open_subtitle": "All the complex processes required for channel opening and verification will be carried out accurately and safely on your behalf by adobaro.🔐",
    "guide_waiting_open_tip": "*The opening and verification process and time required may vary depending on the existing channel operation status.💖",
    "application_requirement": "⚠️Application Conditions",
    "guide_introduction": "Platform Introduction"
  },
  "wallet": {
    "page_title": "My wallet",
    "page_subtitle": "You can view your points and platform revenue.",
    "platform_title": "Platform revenue",
    "a_point_title": "point",
    "overview_title": "Current points",
    "overview_statistic_title": "Total points",
    "overview_btn_deposit": "Purchase",
    "overview_btn_withdrawal": "Withdraw",
    "record_title": "Point history",
    "record_date": "Time",
    "record_content": "Content",
    "record_amount": "Amount",
    "record_enter": "Deposit records",
    "record_out": "Withdrawal records",
    "enter_date": "Time of deposit",
    "enter_content": "History",
    "enter_all": "All",
    "out_date": "Time of withdrawal",
    "out_content": "History",
    "out_all": "All",
    "coin_unit": "point",
    "scene_init_account": "Subscription",
    "scene_open_platform": "Platform opening",
    "scene_bind_platform": "Platform link",
    "scene_new_video": "Content upload",
    "scene_push_video": "Platform upload",
    "scene_withdrawal": "Settled",
    "scene_recharge": "Purchase",
    "scene_platform_settlement": "Platform revenue",
    "scene_gift": "pre-registering",
    "scene_transfer": "Transfer",
    "scene_passport": "Global Pass Verification",
    "scene_invitation": "Invitation Reward",
    "get_tips": "The point has been deposited to [my wallet].",
    "get_rule_title": "What are points?",
    "get_rule_content": "a.coin is a currency used in adobaro that can be used for your channel growth, and can be monetized through applying for settlement.",
    "cost_use_btn": "Use",
    "cost_insufficient_title": "Sorry",
    "cont_insufficient_subtitle": "Insufficient points",
    "cost_insufficient_tips": "{0} points required / you have {0} points"
  },
  "earning": {
    "modify_phone_verify_head": "ID Verification",
    "modify_phone_not_received": "Unable to receive the verification code?",
    "situation_title": "Platform Revenue Status",
    "overview_title": "Revenue Overview",
    "detail_title": "Settlement History",
    "bank_identity_info": "Settlement Account/ID Information",
    "account_info": "account info",
    "monetary_unit_label": "Currency Unit",
    "monetary_unit_cny": "Yuan(CNY)",
    "monetary_unit_krw": "Won(KRW)",
    "monetary_unit_usd": "Dollar(USD)",
    "overview_balance": "Revenue Balance",
    "overview_balance_tip": "Total Unsettled Revenue Balance",
    "overview_withdrawable": "Requestable Settlement Amount",
    "overview_withdrawable_tip": "Requestable Settlement Amount from Revenue Balance",
    "overview_accumulate": "Total Settled Revenue",
    "overview_accumulate_tip": "Total amount settled to date",
    "withdrawable_apply_btn": "Request for Settlement",
    "withdrawable_apply_disable": "The conditions for settlement have not been met, or the settlement application date is not available.",
    "withdrawable_detail_btn": "Revenue Details",
    "withdrawable_mcn_tips": "Please make an inquiry to your agency regarding settlement",
    "withdrawal_directions1": "⁕ Settlement requests can be made between the 10th and 20th of every month. Once your request is approved, the amount will be deposited into your PayPal account on the last day of the following month. (Please note that, in certain cases, delays in the deposit process may occur due to unforeseen circumstances involving platforms or banks. We appreciate your understanding in such situations.)",
    "withdrawal_directions2": "⁕ You can request for revenue settlement when the revenue balance available for settlement exceeds [2,000] yuan.",
    "withdrawal_directions3": "⁕ The settlement service fee rate may vary depending on the membership grade. (The basic fee rate is 30%.)",
    "withdrawal_directions4": "⁕ Monetary income varies based on exchange rates during income settlement.",
    "withdrawal_paypal_not_fount": "PayPal account is not connected. Connect now?",
    "detail_date": "Settlement Request Date",
    "detail_total": "Requested Amount",
    "detail_tax": "Chinese Tax",
    "detail_service_charge": "Service Fee",
    "detail_amount": "Settlement Amount",
    "detail_status": "Settlement Status",
    "detail_action": "View Details",
    "detail_status_ongoing": "Settlement In Progress",
    "detail_status_success": "Settlement Successful",
    "detail_status_failure": "Settlement Failed",
    "detail_status_failure_error": "Payment failed. PayPal account not found. Please enter the correct account information",
    "detail_status_failure_other": "Payment failed. To resolve the issue quickly, please inquire through {0}.",
    "detail_to_account_date": "Expected Date {0}",
    "detail_view_details": "View Details",
    "account_paypal": "Paypal",
    "bank_identity_krw_title": "KRW account information",
    "bank_setting": "Set Up",
    "account_setting": "Set Up",
    "account_not_set": "Not Set Up",
    "account_change": "Modifying Account Information",
    "bank_change": "Edit",
    "bank_not_set": "Not Set Up",
    "bank_name": "Bank",
    "bank_area": "Location of the bank",
    "bank_account_number": "Account Number",
    "bank_account_name": "Account Holder",
    "bank_real": "Income Reporter's Name",
    "bank_identity_number": "Income Reporter's Resident Number",
    "bank_email_code": "E-mail code",
    "account_email_code": "E-mail code",
    "bank_verify_header": "ID Verification",
    "bank_verify_code_title": "Please enter the verification code.",
    "bank_verify_content": "Please complete your identification for the safety of your account.\nPlease enter an emailed authentication number.",
    "bank_verify_code_holder": "Please enter the verification code.",
    "bank_verify_code_error": "The verification code is incorrect.",
    "bank_verify_not_received": "Unable to receive the verification code?",
    "bank_verify_modal_save": "Verify",
    "bank_verify_success": "Verification Successful",
    "bank_verify_failure": "Verification Failed",
    "bank_change_modal_header": "Edit Settlement Account/ID Information",
    "account_info_change_header": "Edit Account Information",
    "bank_name_holder": "Please select a bank.",
    "bank_area_holder": "Please select a bank location.",
    "bank_account_number_holder": "Please enter the account number.",
    "bank_account_name_holder": "Please enter the account holder's name.",
    "bank_real_holder": "Please enter the income reporter's name.",
    "bank_identity_number_holder": "Please enter the income reporter's resident number.",
    "account_email_code_holder": "Please enter your email verification code.",
    "account_email_code_error": "The verification code is incorrect or invalid!",
    "account_paypal_tips": "Paypal provides safe and convenient payment services.\nEnter your PayPal account (email) and complete account information setup.\nPlease register/check your account information on PayPal's official website ({0})",
    "account_paypal_holder": "Please enter your paypal account ",
    "account_paypal_error": "Enter your PayPal account (email)",
    "bank_update_fail": "Failed to update bank information!",
    "account_update_success": "Account information modification completed",
    "account_update_fail": "Account information modification is not complete",
    "confirm_header": "Save",
    "detail_modal_period": "Settlement Period",
    "detail_modal_detail": "View Details",
    "detail_modal_amount": "Platform Revenue",
    "detail_modal_tax": "Chinese Tax",
    "detail_modal_service_fee": "Service Fee",
    "detail_modal_settlement_amount": "Revenue Settlement Amount",
    "detail_modal_bank_info": "Settlement Account Information",
    "detail_modal_bank_btn": "Enter information",
    "detail_modal_to_bank": "You must enter the settlement account information to proceed with the settlement.",
    "detail_modal_tips_time": "· Settlement requests can be made from the 10th to the 20th of each month, and the amount will be deposited into the registered bank account within 30 days after application.",
    "detail_modal_tips_level": "· The service fee rate for settlements varies depending on the membership level. (The basic fee rate is 30%.)",
    "detail_modal_tips_rate": "· Monetary income varies based on exchange rates during income settlement.",
    "detail_modal_tips_bank": "· If the account information is inaccurate, the settlement request may be canceled.",
    "detail_modal_tips_tax": "· As per the business income reporting obligation, 3.3% business income tax will be deducted separately.",
    "detail_modal_apply_btn": "I have read and understood all the above information and hereby apply for settlement.",
    "detail_drawer_money_rate": "Applied Exchange Rate",
    "detail_drawer_money_amount": "Settlement Amount",
    "detail_drawer_tips_krw": "· As per the business income reporting obligation, 3.3% business income tax will be deducted separately.",
    "modal_success_title": "Your settlement application has been completed.",
    "modal_failure_title": "There was an error in the application, please try again later.",
    "modal_close_remind": "Automatically closes in {0} seconds.",
    "platform_situation_title": "Platform Revenue Overview",
    "platform_total": "Monthly Revenue (CNY)",
    "platform_all_platform": "All Platforms",
    "platform_detail": "Platform Revneue Details",
    "platform_table_name": "Platform",
    "platform_table_income": "Platform Revenue (CNY)",
    "platform_table_fee": "Fees (yuan)",
    "platform_table_tax": "Chinese Tax(yuan)",
    "platform_table_balance": "Amount of withdrawal available (yuan)",
    "platform_table_daily_income": "Daily Revenue",
    "platform_drawer_title": "{0} {1} Revenue Details",
    "platform_drawer_tips_wait": "Data collection times may vary by platform, so there may be a 2-3 day difference in the collection period.",
    "platform_drawer_tips_unit": "Revenue amounts are in CNY.",
    "platform_drawer_tips_account": "Daily revenue is based on the day revenue is settled to the MCN account.",
    "platform_drawer_date": "Date",
    "platform_drawer_plan": "Plan",
    "platform_drawer_income": "Platform Revenue (CNY)",
    "platform_drawer_fee": "Fees (yuan)",
    "platform_drawer_tax": "Chinese Tax(yuan)",
    "platform_drawer_balance": "Amount of withdrawal available (yuan)",
    "platform_drawer_total": "Total",
    "platform_drawer_collecting": "Collecting Data...",
    "platform_drawer_show_more": "More",
    "platform_drawer_show_close": "Collapse",
    "platform_drawer_platform_all": "Total Platform Revenue"
  },
  "setting": {
    "title": "Settings",
    "subtitle": "You can manage settings related to adobaro.",
    "user_info": "User info",
    "account_security": "Account security",
    "notification": "Notification",
    "protocol": "Terms of use",
    "invitation": "Invite",
    "user_partner": "Agency",
    "user_partner_empty": "You are not affiliated to an agency yet",
    "user_partner_invite": "You have been invited to become a member",
    "user_partner_belong": "Affiliated",
    "user_partner_name": "Agency:",
    "user_mcn_invite_header": "Member Invitation Notification",
    "user_mcn_accepted_success": "Invitation Accepted",
    "user_mcn_accepted_error": "An error occured, please try again in a moment.",
    "user_mcn_refused_success": "Invitation Declined",
    "user_mcn_refused_error": "An error occured, please try again in a moment.",
    "invitation_upgrade_content": "If you accept the member invitation, you will receive the benefits of the PRO(MCN) plan.\nCurrent plan: {0}\nYour current plan will be automatically canceled upon acceptance, and the payment amount will be refunded to you based on the number of days of use.\nWould you like to accept the member invitation?\n*Refund of payment may take up to 7 business days.",
    "user_partner_invite_title": "You have received an invitation!",
    "user_partner_invite_directions": "Please check the agency info before you accept/decline the invitation.\nAll members will receive the benefits of the PRO plan :)",
    "user_partner_invite_tips": "*Upon acceptance, your information will be disclosed to the agency, and it is confirmed that you have agreen to the agency's revenue settlement policy.",
    "user_partner_invite_refuse": "Decline",
    "user_partner_invite_footer_statement": "Please be advised that once you become affiliated to an agency, adobaro will not be involved in any disputes, including copyright issues, between you and your agency, and will have no legal liability. Please contact your agency for billing and settlement policies.",
    "user_partner_auth": "Agency Permission Settings",
    "user_partner_auth_check": "Can Monitor",
    "user_partner_auth_release": "Can Upload",
    "user_partner_rescind": "Terminate Agreement",
    "user_partner_auth_footer_statement": "You can set up agency permissions.\nPlease select between Can Monitor/Upload",
    "user_partner_rescind_title": "Are you sure you want to request agreement termination?",
    "user_partner_rescind_directions": "Termination of the affiliation is considered to have been pre-arranged between the agency and the member, and a termination notice will be sent to the agency.\n\nThe agreement will be terminated only when the agency agrees to terminate the affiliation, and partner rights will expire when the agency agrees to terminate the agreement.\n\nWhen the agreement ends, the PRO pricing plan provided as a membership benefit will end and automatically change to the FREE plan.",
    "user_partner_rescind_btn": "Terminate Agreement",
    "user_uid": "UID",
    "user_phone": "Mobile number",
    "user_email": "E-mail address",
    "user_password": "Password",
    "user_third_party_login": "Third party log in",
    "user_not_set": "Not set",
    "user_set_password": "Change password",
    "user_to_change": "Change",
    "user_bound": "Linked",
    "user_to_bound": "Link",
    "user_bound_google": "Linked with Google",
    "user_unbound_google": "Link with Google",
    "message_google_bound": "The Google account is already linked to another adobaro account",
    "user_problem_center": "Customer service",
    "user_phone_title_bind": "Enter mobile phone number",
    "user_phone_subtitle_bind": "For account safety, it will be proceeded after confirming your mobile verification code.",
    "user_phone_title_new": "Enter new mobile phone number",
    "user_login_country_check": "Select your country",
    "user_modify_phone_verify_fail": "Failed to verify mobile phone number",
    "user_support_email": "Customer service e-mail",
    "user_code_not_received_tip": "If you do not receive a verification code, process it via {0} .",
    "user_code_not_received": "Haven't received your verification code?",
    "user_verify_header": "ID verification",
    "user_modify_phone_header": "Change mobile phone number",
    "user_modify_email_update_button": "Change e-mail",
    "user_modify_email_explanation": "For account safety, it will be proceeded after confirming your e-mail verification code",
    "user_modify_email_old_holder": "Enter your current e-mail address",
    "user_modify_email_old_popover": "If you don't remember the email address you joined, please contact {0} .",
    "user_modify_email_forgot": "Forgot your e-mail?",
    "user_modify_email_verify_fail": "Failed to verify e-mail",
    "user_modify_new_email": "New e-mail",
    "user_modify_email_new_holder": "Enter new e-mail",
    "user_modify_email_title": "Change e-mail",
    "user_modify_password_change_button": "Change password",
    "user_modify_password_title": "Change password",
    "user_modify_password_explanation": "For account safety, it will be proceeded after confirming your e-mail verification code.",
    "user_modify_new_password": "New password",
    "user_modify_password_new_holder": "Enter new password",
    "user_modify_password_confirm_holder": "Confirm new password",
    "user_modify_password_head": "Change password",
    "security_signin": "Log in security",
    "security_credit": "Credibility",
    "security_signin_date": "Logged in on",
    "security_signin_mode": "Logged in with",
    "security_signin_third": "Third party log in",
    "security_signin_google": "Log in with Google",
    "security_signin_account": "Logged in account",
    "security_signin_ip": "IP address",
    "security_signin_country": "Country",
    "security_signin_action": "Execute",
    "delete_confirm_title": "Are you sure?",
    "credit_action_date": "Executed on",
    "credit_action_behavior": "Action",
    "credit_ score": "Credibility",
    "notice_spread": "Marketing notification",
    "notice_platform": "Platform notification",
    "notice_sms": "SMS notification",
    "notice_email": "e-mail notification",
    "notice_adobaro": "adobaro",
    "notice_operate": "Operating platforms",
    "notice_select_holder": "Please select",
    "notice_agree": "Agree",
    "notice_disagree": "Disagree",
    "user_problem_question_1": "Question 1",
    "user_problem_answer_1": "Answer",
    "user_problem_question_2": "Question 2",
    "user_problem_answer_2": "Answer",
    "user_problem_question_3": "Question 3",
    "user_problem_answer_3": "Answer",
    "user_problem_question_4": "Question 4",
    "user_problem_answer_4": "Answer",
    "protocol_user": "adobaro User Agreement",
    "protocol_privacy": "The Privacy Policy of adobaro",
    "protocol_marketing": "Marketing Consent Form",
    "notification_history": "Notification History",
    "notice_date": "Date and Time",
    "notice_title": "Notification Title",
    "notice_detail": "Notification Content",
    "delete_all_btn": "Delete All",
    "notice_delete_all": "Are you sure you want to delete all?",
    "notice_delete_all_subtitle": "After deleting all, you can check in the notification history.",
    "notice_cancel": "Cancel",
    "notice_details": "Details",
    "notice_read_all": "Read all",
    "notice_read_all_confirm": "Do you want to treat all as read?",
    "invitation_title": "Invite friend",
    "invitation_link": "Invitation Link",
    "invitation_copy_link": "Copy",
    "invitation_copy_link_tip": "Copy successfully.",
    "invitation_copy_content": "Try adobaro — the best trendy cross-border platform for content creators! Get started with me!",
    "invitation_bonus": "Invitation Reward Details",
    "invitation_points_rewards": "point",
    "invitation_signup": "adobaro sign-up",
    "invitation_global_pass": "Global Pass Verification",
    "invitation_open_platform": "Opening of the Platform (the first time)",
    "invitation_publish_video": "Uploading content (first time)",
    "invitation_tips": "* Additional 100p for completion of 4 missions",
    "invitation_history": "Invitation History",
    "invitation_account": "Channel name",
    "invitation_time": "Achieved on",
    "invitation_type": "Reasons for  Reward",
    "invitation_type_extra": "Bonus point",
    "invitation_type_unknown": "unknown",
    "user_unbind": "Cancellation Request",
    "user_unbind_confirm_title": "Would you like to apply for contract cancellation?",
    "user_unbind_confirm_des": "Cancellation is possible after the company agrees, and immediately after cancellation, the company member benefits will be terminated.",
    "invitation_type_extra_invite": "Invitation _ Additional Rewards",
    "invitation_signup_invite": "Invitation_Register adobaro",
    "invitation_global_pass_invite": "Invitation _ Global Pass Certification",
    "invitation_publish_video_invite": "Invitation_Publish Video (first time)",
    "invitation_open_platform_invite": "Invitation_Open the platform (the first time)"
  },
  "plan": {
    "segmented_month": "Monthly",
    "segmented_year": "Yearly",
    "segmented_year_discount": "16% Discount",
    "unit_month": "Month",
    "unit_year": "Year",
    "title_free": "FREE",
    "title_basic": "BASIC",
    "title_pro": "PRO",
    "subtitle_free": "Minimum Plan for your activities, platforms are monetized",
    "subtitle_basic": "Plan for creators with regular activity rates, 1 month free promotion, 90% discount from regular price",
    "subtitle_pro": "Unlock everything, unlimited(maximum) activities per platform guaranteed, 90% discount from regular price",
    "original_label": "Original Price:",
    "btn_current": "Current Plan",
    "btn_upgrade": "Upgrade",
    "btn_downgrade": "Downgrade",
    "btn_convert": "Switch",
    "btn_upgrade_experience": "Upgrade (Free for 1 month)",
    "btn_buy_now": "Buy now",
    "btn_buy_now_trial": "Buy now(1 month free trial)",
    "msg_exists": "Upgrade survices are not currently supported. Please stay tuned.",
    "msg_paid": "Payment successful. We are upgrading your membership benefits for you",
    "price_tips_current": "This is your current plan.",
    "price_tips_experience": "Payment begins after 30 days.",
    "price_tips_new_plan": "The selected option will be applied after the current plan ends.",
    "price_tips_pay_upgrade": "You can upgrade after paying the difference.",
    "price_tips_save_money": "You can save {0} dollars every year.",
    "expire_date": "Valid until {0}",
    "rights_title": "Key Benefits",
    "rights_platform_label": "Available Platforms",
    "rights_platform_free": "1",
    "rights_platform_basic": "3",
    "rights_platform_pro": "All",
    "rights_service_charge_label": "Discounted commission on settlement",
    "rights_service_charge_pro": "5%",
    "rights_upload_label": "Available Uploads",
    "rights_upload_free": "twice/week",
    "rights_upload_basic": "once/daily per platform",
    "rights_upload_pro": "Unlimited (max 5 times per platform per day)",
    "rights_space_label": "Storage",
    "rights_space_free": "5GB",
    "rights_space_basic": "5GB",
    "rights_space_pro": "10GB",
    "rights_check_label": "Content Pre-review",
    "rights_check_basic": "√",
    "rights_check_pro": "√",
    "rights_copyright_label": "Copyright Protection",
    "rights_copyright_pro": "√",
    "rights_settle_label": "Fast Settlement",
    "rights_settle_pro": "√",
    "rights_professional_label": "1-on-1 Management",
    "rights_professional_pro": "√",
    "footer_current_plan": "Current Plan:",
    "footer_update_plan": "Plan update",
    "footer_help_center": "View details in Help Center.",
    "details_title": "Subscription plan details",
    "details_monthly_plan": "Monthly",
    "details_year_plan": "Annually",
    "details_trial_one_month": "1 month free trial",
    "details_total": "Total",
    "details_deduction_monthly": "Monthly charges will be made.",
    "details_deduction_discount": "No charge will be made today for the discounted benefit.",
    "details_deduction_date": "The renewal fee will be deducted {0}",
    "payment_protocol": "Your subscription will be automatically renewed every month until cancellation. Please read {0} and {1} and confirm your agreement",
    "payment_protocol_use": "User Agreement",
    "payment_protocol_privacy": "Privacy Policy",
    "result_success_title": "Payment successful.",
    "result_success_subtitle": "Benefits are being provided.",
    "result_success_btn": "Try now",
    "downgrade_limit_content": "The current plan is {0} and the use of the platform is limited. Click on the upgrade to unlock more benefits.",
    "downgrade_btn_keep": "Keep {0}",
    "downgrade_btn_renew": "Renew now",
    "downgrade_select_platform": "Select {0} platforms to continue using, other platforms will enter locked state",
    "downgrade_select_platform_unfroze": "Your current plan is {0}, and {1} platforms are available. Please choose the platform you would like to enter.",
    "downgrade_msg_unfreeze_success": "Unlock successfully!",
    "downgrade_msg_unfreeze_fail": "Unlock failed!"
  },
  "email": {
    "1": "Title and description review",
    "2": "Sensitive or inappropriate content has been detected.",
    "3": "Does not match with content.",
    "4": "Content that violates Chinese laws has been detected",
    "6": "Thumbnail review",
    "7": "The thumbnail contains violations. Revealing genitals or physically sensitive areas, tt could be suspected as an offensive image, or it contains vulgar content, profanity, and content that is against public order and morals.",
    "9": "The thumbnail is overstretched, the screen is lost, or the brightness is too low or overexposed.",
    "10": "The thumbnail resolution is too low.",
    "11": "The thumbnail and the video content does not match.",
    "13": "Rejected due to the background has been removed, the portait does not match, or the image is low-quality.",
    "14": "The thumbnail contains external content or information.",
    "15": "Content review",
    "16": "There is a problem with the content quality.",
    "17": "The video is low resolution, the screen loses, and the brightness/contrast/resolution/blurry screen makes it difficult to distinguish the subject.",
    "18": "Invalid video URL, 404 error, and the video is shorter than 6 seconds.",
    "19": "The video content is missing a clear storyline.",
    "20": "The video does not have any clear content.",
    "21": "The video does not play smoothly and lags.",
    "22": "The screen shakes noticeably or continuously for more than 5 seconds and flashes continuously for more than 3 seconds, which affects the viewing experience.",
    "23": "The subject of the screen is not complete, and is constantly clipped(≥3 seconds), severely stretched or compressed, has black outlines on either side or is filled with black outlines.",
    "24": "The content has been rejected because there is no audio.",
    "25": "The black screen of the opening and ending should not exceed 3 seconds.",
    "26": "The quality of the image is low.",
    "27": "The video content may cause legal issues.",
    "28": "The video content violates the Chinese law as it is believed to insult images or words of China's national leaders, attackers, prosecutors, state institutions, national flag, etc.",
    "29": "The video content contains sexual, blood, or horror elements that are illegal, violating or vulgar.",
    "30": "The video content contains elements related to cults, strife, and reactionary organizations.",
    "31": "The video content may harm the physical and mental health of minors or people with disabilities.",
    "32": "Minors cannot shoot commercial marketing content as representatives.",
    "33": "The video content violates elements related to public order and social values. This includes infidelity, domestic violence, display, discrimination, propaganda, smoking, foul language, pranks, bad behavior, abuse, drinking, tattoss, etc.",
    "34": "The video content contains sexually suggestive behavior, vulgar scenes, vulgar dnacing, inappropriate sexual relationship, etc.",
    "35": "The video content contains severe exposure.",
    "36": "There is a problem with the sound quality of the video.",
    "37": "The video suddenly mutes or the audio disappears, the audio tracks overlap inconsistently or are not related, and the audio is severely out of sync with the video.",
    "38": "There is too much noise.",
    "39": "There is a problem with the subtitles quality.",
    "40": "Subtitles are unclear, lost, overlapping, or sentences are delivered out of order.",
    "41": "The foreign langue video is missing Chinese subtitles or is not recommended due to subtitles quality issues.",
    "42": "Subtitles are not synchronized with or irrelevant to the video or audio.",
    "43": "Subtitles are cut off.",
    "44": "Subtitles are covering most of the person's face.",
    "45": "Copyright issue",
    "46": "Uploading content that infringes the rights and interests of copyright holders is prohibited.\nIncluding but not limited to:\n① Dissemination and sale of works that have already been published or have not yet been published without the permission of the copyright holder\n② Impersonating copyright holders or other interested parties\n③ Copying content prohibited by the copyright holder or the content of the creator who has been active on the site for a long time",
    "47": "This video has already been uploaded.",
    "signup_title": "Welcome!",
    "signup_subtitle": "This is the adobaro sign up procedure.",
    "signup_set_password_tip": "For account security, please set up your password after verifying your e-mail.",
    "signup_set_password": "Set up password",
    "sigin_warning_title": "Security issue",
    "sigin_warning_subtitle": "An overseas IP connection has been detected.",
    "sigin_warning_midtitle": "For account security, please proceed with the log in verification below.",
    "sigin_warning_verify": "Log in verification",
    "sigin_warning_not_myself": "If you did not log in,",
    "sigin_warning_change_pwd": "please change your password immediately.",
    "sigin_warning_pwd": "Change password",
    "reset_pwd_title": "Reset password",
    "reset_pwd_subtitle": "You have been requested to reset your password.",
    "reset_pwd_midtitle": "For account security, you can reset your password after verifying your e-mail.",
    "reset_pwd_set": "Password settings",
    "release_title": "Upload status",
    "release_audit_fail": "Review failed",
    "release_upload_video": "The content you uploaded",
    "release_audit_reason": "has failed the review.\nThe reason is as follows.",
    "release_re_upload": "Re-upload",
    "release_success": "Upload complete",
    "release_up_video": "The content you uploaded",
    "release_up_platform": "has been successfully uploaded on platform {{.Platform}}.",
    "release_view_data": "Content data",
    "op_notice_title": "Platform opening status",
    "op_notice_fail": "Failed to open platform",
    "op_notice_fail_platform": "The {{.Platform}} platform you applied for could not be opened.",
    "op_notice_fail_reason": "Reason for failure",
    "op_notice_success": "Successfully opened platform",
    "op_notice_success_platform": "The {{.Platform}} platform you applied for has been opened.",
    "op_notice_ai": "Suitable channel names and channel descriptions have been optimized and applied to the platform through adoba.AI.",
    "op_notice_view_data": "View platform information",
    "certification_title": "Pass verificaiton notice",
    "certification_subtitle_global_fail": "Global Pass review failed",
    "certification_global_fail": "Your Global Pass verification has failed the review.",
    "certification_subtitle_creator_fail": "Creator Pass review failed",
    "certification_creator_fail": "Your Creator Pass verification has failed the review.",
    "certification_view_reason": "Reason for failure",
    "certification_in_applying": "You can use the following features after passing the verification review.",
    "certification_global_success": "Global Pass verification complete",
    "certification_subtitle_global_success": "Your Global Pass verification has been completed.",
    "certification_open_now": "You can use the following features for various creative activities from now on.",
    "certification_subtitle_creator_success": "Creator Pass verification complete",
    "certification_creator_success": "Your Creator Pass verification has been completed.",
    "certification_earnings": "More content revenue generation",
    "certification_auth_cancel": "Pass verification cancellation notice",
    "certification_cancel_start": "Your {{开始时间}} Pass verification has been successfully canceled.",
    "certification_get_a_coin": "Platform revenue will be suspended from the date of application, but a.coins can still be used within adobaro",
    "certification_a_coin": "Learn about a.coin",
    "certification_re_register": "If you would like to apply again for platform opening,",
    "certification_cancel_end": "Platform can be reopened after {{结束时间}}.",
    "certification_thank": "Thank you for using our service.",
    "captcha_notice_title": "Verification code notice",
    "captcha_notice_subtitle": "Check verification code",
    "captcha_notice_hello": "Hello,",
    "captcha_notice_platform": "Platform",
    "captcha_notice_send_code": "verification code will be sent soon.",
    "captcha_notice_platform_secure": "Mobile phone verification code is required due to platform security.",
    "captcha_notice_sgin": "Please check your message inbox.\nIf you have already entered your verification code within adobaro, please ignore this e-mail.",
    "captcha_notice_fill_code": "Send Code",
    "captcha_notice_not_myself": "If you didn't apply,",
    "captcha_notice_handle": "please contact adobaro customer service.",
    "captcha_notice_foot": "This e-mail does not support replies.",
    "verify_code_title": "Verification number notice",
    "verify_code_subtitle": "Please check your verification code.",
    "verify_code_hello": "Hello.",
    "verify_code_platform": "Platform",
    "verify_code_send_code": "The verification code will be sent once you select the button below.",
    "verify_code_resend": "Please check your verification code again.",
    "verify_code_security": "Mobile phone verification code is required due to platform security.",
    "verify_code_sign": "Please note.",
    "verify_code_fill_code": "Eneter verification code",
    "email_subject_sign_account": "[adobaro] Welcome to adobaro!",
    "email_subject_reset_pwd": "[adobaro] Please reset your password",
    "email_subject_sign_warning": "[adobaro] Log in security issue",
    "email_subject_auth": "[adobaro] Have you applied for the Pass verification?",
    "email_subject_release": "[adobaro] Progress of uploaded content",
    "email_subject_verify_code": "[adobaro] Verification code for account security",
    "email_subject_op_result": "[adobaro] Platform opening application result",
    "email_snject_verify_email": "[adobaro] Verification e-mail",
    "email_subject_code_platform": "[adobaro] Verification to open the platform.",
    "email_subject_code_plus": "[adobaro] Verification to Global Plus Pass",
    "email_subject_code_auth": "[adobaro] Verification to Global Pass",
    "email_subject_code_video": "[adobaro] Verification to upload",
    "email_verify_code_subtitle": "This is a process for verification.",
    "email_verify_code_hello": "Hello, a verification code will be sent to verify your ID.",
    "email_verify_code_input": "Please enter below and proceed with verification.",
    "email_verify_code_not_myself": "If it is not you, please log in and change your email.",
    "email_verify_code_handle": "The verification code is not requested by the customer center either, so please be careful not to share it with others or leak it.",
    "email_verify_code_recover": "This e-mail does not support replies.",
    "email_verify_code_warning": "If you have any problems, please contact customer service.",
    "auth_err_1": "Passport photo is difficult to verify.\nPlease check and upload again.",
    "auth_err_2": "It is difficult to cross-check you and your passport image.\nPlease check and upload again.",
    "auth_err_3": "Passport expiration date has expired.\nPlease renew your passport.",
    "auth_err_4": "Passport information does not match.\nPlease enter the information correctly.",
    "auth_err_5": "The passport information already exists.",
    "auth_err_6": "Screenshot image is not clear enough.\nPlease check and upload again.",
    "auth_err_7": "It is difficult to cross-check you and your dashboard screenshot image.\nPlease check and upload again.",
    "auth_err_8": "The information extracted from the image and the information you entered do not match.\nPlease check and upload again.",
    "auth_err_9": "The dashboard information already exists.",
    "5 (这个不会存在）": "You have exceeded the number of characters that can be entered.",
    "8 （不存在）": "The thumbnail contains outlines.",
    "12 (不存在)": "The thumbnail text is cut off.",
    "release_1": "Upload failed.",
    "release_2": "Hello.",
    "release_3": "The video you uploaded on {{.Platform}}",
    "release_4": "Upload failed for the following reasons.",
    "release_5": "Go to Reupload"
  }
};
export default en